import PropTypes from 'prop-types';
import Checkbox from '../Button/CheckButton';
import { containerCheckBox } from './styles';

const CheckBoxForm = (props) => {
  const { id, label, value, onChange, className } = props;
  return (
    <Checkbox checked={value} onChange={(newValue) => onChange(id, newValue)} css={containerCheckBox(className)}>
      {label}
    </Checkbox>
  );
};

CheckBoxForm.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default CheckBoxForm;
