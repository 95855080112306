import { useState, useEffect, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useNavigationControls } from '../NavigationContext';
import { MENU_SEPARATOR_NAME } from './MenuSeparator';
import { MenuSubItem } from './MenuItem';
import { sideNavContainer, menuListContainer, menuSubListContainer } from './styles';

const MenuList = ({ sideNavChildrens }) => {
  const location = useLocation();
  const { isOpen } = useNavigationControls();
  const [shownSubMenuList, setShownSubMenuList] = useState([]);
  const hasSubMenus = shownSubMenuList?.length;

  useEffect(() => {
    setShownSubMenuList([]);
  }, [location]);

  const MenuListItems = sideNavChildrens.map((menu, i) =>
    cloneElement(menu, {
      key: `${menu.label}-${i}`,
      ...(menu.type.displayName !== MENU_SEPARATOR_NAME && {
        isOpen,
        onSelect: setShownSubMenuList,
      }),
    }),
  );

  return (
    <nav css={sideNavContainer} role="presentation">
      <div className="menu-list-container" css={menuListContainer(isOpen, hasSubMenus)}>
        {MenuListItems}
      </div>
      <div className="menu-sublist-container" css={menuSubListContainer(hasSubMenus)}>
        {shownSubMenuList.map((subMenu, ind) => (
          <MenuSubItem key={`${subMenu}-${ind}`} {...subMenu} />
        ))}
      </div>
    </nav>
  );
};

MenuList.propTypes = {
  sideNavChildrens: PropTypes.array,
};

export default MenuList;
