export const wrap = (theme) => ({
  '& > h4': {
    textTransform: 'uppercase',
    textAlign: 'center',
    margin: '0.5em 0',
    color: theme.secondaryDarker,
  },

  '@media (max-width: 767px)': {
    padding: '0 2em',
  },
});

export const listWrap = {
  '& .list-buffer': {
    height: '2rem',
  },
};

export const footer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '3em 0',
};
