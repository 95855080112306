import { keyframes } from '@emotion/react';
import { bounceInAnimation, bounceOutAnimation } from '../styles';

const textColorMap = (theme) => ({
  secondary: theme.darkText,
  warning: theme.textLightPrimary,
});

const backgroundColorMap = (theme) => ({
  primary: theme.darkText,
  secondary: theme.secondaryDarker,
  gray: theme.primaryDark,
  success: theme.successLight,
  warning: theme.warningLight,
  info: theme.infoLight,
});

const hoverBgColorMap = (theme) => ({
  primary: theme.secondaryDarker,
  secondary: theme.darkText,
});

const hoverColorMap = (theme) => ({
  secondary: theme.secondaryDarker,
});

export const buttonContainer = (
  { type, theme, disabled, color, backColor, hoveBgColor, outline, clear, small, large, noHover },
  isLoading,
) => {
  const background = theme[backColor] ?? backgroundColorMap(theme)[type] ?? theme.primary;
  const textColor = theme[color] ?? textColorMap(theme)[type] ?? theme.secondaryDark;
  const hoverBgColor = theme[hoveBgColor] ?? hoverBgColorMap(theme)[type] ?? theme.secondaryDark;
  const hoverColor = theme[hoveBgColor] ?? hoverColorMap(theme)[type] ?? theme.darkText;

  return [
    {
      cursor: disabled ? 'not-allowed' : 'pointer',
      padding: '1em 2em',
      textTransform: 'uppercase',
      color: textColor,
      lineHeight: 1,
      textAlign: 'center',
      opacity: isLoading || disabled ? 0.6 : 1,
      background,
      userSelect: 'none',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
      fontSize: '1rem',
      maxWidth: '100%',
      transition:
        'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out, width .4s ease-in-out',
      '& > .arrow-style': {
        width: '1rem',
        marginLeft: '1em',
        '& > path': {
          stroke: textColor,
        },
      },
      '& > i': {
        marginRight: 6,
      },
    },
    !noHover && {
      '&:hover': {
        color: hoverColor,
        background: hoverBgColor,
        '& > .arrow-style > path': {
          stroke: hoverColor,
        },
      },
    },
    (disabled || isLoading) && {
      '& *': {
        pointerEvents: 'none',
      },
    },
    small && {
      fontSize: '0.8rem',
      padding: '0.6rem 1rem',
    },
    large && {
      fontSize: '1.55rem',
      padding: '0.95rem 2.5rem',
    },
    outline && {
      border: `1px solid ${theme.primary}`,
    },
    clear && {
      backgroundColor: 'transparent',
      color: background,
      border: '1px solid transparent',

      '&:hover': {
        borderColor: background,
      },
      '&:active': {
        color: textColor,
      },
    },
  ];
};

export const checkButtonContainerBase = ({ label }) => [
  {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  label && {
    '& > .input-indicator': {
      marginRight: 8,
    },
    '& > .label': {
      fontSize: '1.3rem',
    },
  },
];

const checkButtonAnimation = keyframes`
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
`;

export const checkButtonContainer = (hasLabel) => [
  ...checkButtonContainerBase(hasLabel),
  {
    position: 'relative',
    borderRadius: 6,
  },
];

export const checkButtonIndicator = (isChecked, size, inverse, theme) => [
  {
    width: size,
    height: size,
    display: 'block',
    position: 'relative',
    outline: 'none',
    background: theme.white,
    border: 'none',
    cursor: 'pointer',
    margin: 0,
    padding: 0,
    borderRadius: 6,
    transition: 'box-shadow .3s',
  },
  isChecked && {
    transitionDelay: inverse ? '.4s' : 'unset',
  },
];

export const checkButtonSvg = (isChecked, size, inverse, theme) => [
  {
    pointerEvents: 'none',
    fill: 'none',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    position: 'absolute',
    top: 0,
    left: 0,
    width: size,
    height: size,
    stroke: inverse ? theme.primary : theme.white,
    transform: `scale(${inverse ? 1 : 0}) translateZ(0)`,
  },
  inverse && {
    strokeDasharray: '86.12',
    strokeDashoffset: '86.12',
    transition: 'stroke-dasharray .6s, stroke-dashoffset .6s',
  },
  isChecked &&
    (inverse
      ? {
          strokeDasharray: '16.1 86.12',
          strokeDashoffset: '102.22',
        }
      : {
          animation: `${checkButtonAnimation} .4s linear forwards .2s`,
        }),
];

const radioButtonBorderAnimation = ({ startOffset, endOffset }) => keyframes`
  from {
    stroke-dashoffset: ${startOffset};
  }
  to {
    stroke-dashoffset: ${endOffset};
  }
`;

export const radioButtonContainer = (isChecked, size, hasLabel, theme) => [
  ...checkButtonContainerBase(hasLabel),
  {
    '& > svg': {
      width: size,
      height: size,
      border: `1px solid ${isChecked ? 'transparent' : theme.border}`,
      borderRadius: '50%',
      transition: 'border-color 0.5s ease',
    },

    '& #border': {
      fill: 'none',
      stroke: isChecked ? theme.primary : theme.border,
      strokeWidth: 6,
      strokeLinecap: 'round',
      animation: `${radioButtonBorderAnimation({
        startOffset: isChecked ? size * 6 : 0,
        endOffset: isChecked ? 0 : size * 6,
      })} 0.5s ease forwards`,
      transition: 'stroke 0.5s ease',
      strokeDasharray: `${size * 6}`,
    },

    '& #dot': {
      fill: theme.primary,
      transformOrigin: '50% 50%',
      ...(isChecked ? bounceInAnimation({ duration: 0.8 }) : bounceOutAnimation({ duration: 0.5 })),
    },
  },
];

const switchButtonStartAnimation = (size) => keyframes`
  0% {
    right: ${size.padding}px;
    width: ${size.smallWidth};
  }
  25% {
    right: ${size.padding}px;
    width: calc(100% - ${size.padding * 2}px);
  }
  50% {
    right: auto;
    width: calc(100% - ${size.padding * 2}px);
    left: ${size.padding}px;
  }
  100% {
    left: ${size.padding}px;
    width: ${size.smallWidth};
  }
`;

const switchButtonEndAnimation = (size) => keyframes`
  0% {
    left: ${size.padding}px;
    width: ${size.smallWidth};
  }
  25% {
    left: ${size.padding}px;
    width: calc(100% - ${size.padding * 2}px);
  }
  50% {
    right: ${size.padding}px;
    width: calc(100% - ${size.padding * 2}px);
    left: auto;
  }
  100% {
    right: ${size.padding}px;
    width: ${size.smallWidth};
  }
`;

export const switchToggle = (size, isChecked, theme) => ({
  cursor: 'pointer',
  width: size.width * 2,
  height: size.width,
  display: 'inline-flex',
  position: 'relative',
  backgroundColor: theme.white,
  textAlign: 'left',
  borderRadius: size.width,
  boxSizing: 'border-box',
  perspective: 300,
  border: `1px solid ${isChecked ? theme.primary : theme.border}`,
  transition: 'border .8s ease-in-out',

  '&:before': {
    content: "''",
    width: size.smallWidth,
    height: size.smallWidth,
    position: 'absolute',
    right: size.padding,
    top: size.padding - 1,
    display: 'inline-flex',
    textAlign: 'center',
    borderRadius: size.smallWidth,
    transition: 'all 1s ease-in-out',
    transformOrigin: '0% 50%',
    transform: 'rotateY(0deg)',
    animation: `${
      isChecked ? switchButtonEndAnimation(size) : switchButtonStartAnimation(size)
    } 0.6s ease-in-out forwards`,
    backgroundColor: isChecked ? theme.primary : theme.whiteGrayDark,
  },
});

export const buttonDropdownContainer = {
  position: 'relative',
  outline: 'none',
  fontSize: '16px',
};

const itemHeight = 51;

export const buttonDropdownOptionsContainer = (isOpened, maxNumber, optionListClassName, theme) => ({
  position: 'absolute',
  top: '100%',
  right: 0,
  zIndex: 150,
  width: 'auto',
  color: theme.textLightSecondary,
  height: 'auto',
  maxHeight: maxNumber * itemHeight - itemHeight / 2,
  overflowY: 'auto',
  backgroundColor: 'white',
  transform: `scaleY(${isOpened ? 1 : 0})`,
  transformOrigin: 'top',
  transition: 'transform 400ms ease-in-out',
  borderTop: isOpened ? '1px solid #DDDDDD' : 'none',
  borderRadius: '0 0 4px 4px',
});

export const buttonDropdownOptionItem = (theme) => ({
  padding: '16px 32px 16px 16px',
  color: theme.textLightPrimary,
  backgroundColor: 'transparent',
  display: 'inline-flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  width: '100%',
  '&:focus': {
    outline: 'none',
  },
  '& i, & img': {
    marginRight: 10,
  },
});

export const checkGroup = {
  '& > *': {
    marginRight: 8,
  },
};
