import { css, keyframes } from '@emotion/react';
import { Types } from './Store';

const generateBackColor = (type, theme) => {
  switch (type) {
    case Types.SUCCESS:
      return theme.successLight;
    case Types.ERROR:
      return theme.errorLight;
    case Types.WARNING:
      return theme.warningLight;
    case Types.INFO:
      return theme.infoLight;
    default:
      return theme.successLight;
  }
};

const generateColor = (type, theme) => {
  switch (type) {
    case Types.SUCCESS:
      return theme.successDark;
    case Types.ERROR:
      return theme.errorDark;
    case Types.WARNING:
      return theme.warningDark;
    case Types.INFO:
      return theme.infoDark;
    default:
      return theme.successDark;
  }
};

const animation = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const notification = (type, isHidden, theme) =>
  css({
    padding: 16,
    marginBottom: 16,
    color: theme.white,
    backgroundColor: generateBackColor(type, theme),
    borderRadius: 10,
    transform: isHidden ? 'translateX(calc(100% + 20px))' : 'none',
    transition: 'transform 0.4s ease-in-out',
    animation: `${animation} 0.4s ease-in-out`,
    cursor: 'pointer',

    '&:last-of-type': {
      marginBottom: 0,
    },
  });

export const notifContainer = (type, theme) =>
  css({
    display: 'flex',
    alignItems: 'center',
    maxWidth: 500,
    fontSize: '1rem',
    color: generateColor(type, theme),

    '& > svg, & > i': {
      marginRight: 12,
      width: '1.3rem',
    },
  });

export const allNotificationsContainer = css({
  zIndex: 2100,
  position: 'fixed',
  top: 20,
  right: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  overflowY: 'auto',
  overflowX: 'hidden',
  maxHeight: 'calc(100% - 40px)',
});
