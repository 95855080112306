import { css, keyframes } from '@emotion/react';

export const formContainer = {
  width: '100%',
};

export const formFieldsContainer = css({
  marginBottom: 12,
});

// Input styles start (base one)
export const inputContainer = (isHorizontal, className) =>
  css(
    {
      display: 'flex',
      flexDirection: isHorizontal ? 'row' : 'column',
      alignItems: 'flex-start',
      justifyContent: isHorizontal ? 'space-between' : 'flex-start',
      position: 'relative',
      width: '100%',
      height: isHorizontal ? '48px' : '75px',
      outline: 'none',
    },
    className,
  );

export const horizontalContainer = css({
  flex: 1,
  position: 'relative',
});

export const inputLabel = (isHorizontal, theme) =>
  css({
    fontWeight: 600,
    opacity: 1,
    color: theme.textLightSecondary,
    margin: `
      ${isHorizontal ? '12px' : 0}
      ${isHorizontal ? '24px' : 0}
      ${isHorizontal ? 0 : '8px'}
      0`,
  });

export const inputField = (
  hasError,
  hasText,
  // theme
) =>
  css(
    {
      fontWeight: 400,
      lineHeight: 'normal',
    },
    hasError
      ? {
          // border: `1px solid ${theme.error}`,
        }
      : hasText
      ? {
          // border: `1px solid ${theme.success}`,
          borderRadius: 3,
        }
      : {},
  );

const helpTextAnimation = keyframes`
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 24px;
    opacity: 1;
  }
`;

export const inputHelpContainer = (hasError, theme) =>
  css({
    fontSize: '13px',
    lineHeight: 1.54,
    color: hasError ? theme.errorDark : theme.successDark,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 6,
    fontWeight: 400,
    animation: `${helpTextAnimation} 400ms ease-out`,
  });

export const inputHelpIcon = {
  marginRight: 4,
  width: 14,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& > img': {
    width: '100%',
    height: 'auto',
    maxHeight: '100%',
  },
};

export const inputFieldRightIcon = (isPasswordType) =>
  css({
    position: 'absolute',
    right: isPasswordType ? 38 : 8,
    bottom: 26,
  });

export const inputFieldVisibilityIcon = css({
  position: 'absolute',
  right: 8,
  bottom: 26,
});
// Input styles end (base one)

// Input styles start (dynamic one)
export const dynamicInputContainer = (isReadOnly, className, theme) =>
  css(
    {
      borderRadius: 8,
      paddingLeft: 8,
      '&:hover': {
        backgroundColor: isReadOnly ? 'transparent' : theme.whiteGray,
      },
      '@media (max-width: 991px)': {
        marginTop: 8,
      },
    },
    className,
  );

export const dynamicInputError = (theme) =>
  css({
    fontSize: '12px',
    lineHeight: 1,
    color: theme.errorDark,
  });

export const dynamicInputErrorAdd = (theme) =>
  css(dynamicInputError(theme), {
    paddingLeft: 12,
  });

export const dynamicInputAddLabel = (hasError, isDisabled, required, theme) =>
  css({
    color: hasError ? theme.errorDark : isDisabled ? theme.textLightDisabled : theme.primary,
    fontSize: 16,
    lineHeight: hasError ? '17px' : '21px',
    padding: required ? (hasError ? '5px 0' : '8px 0 10px') : hasError ? '8px 0 0' : '8px 0 12px',
    outline: 'none',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  });

export const dynamicInputLabel = (hasError, isDisabled, theme) =>
  css({
    color: hasError ? theme.errorDark : isDisabled ? theme.textLightDisabled : theme.textLightSecondary,
    padding: hasError ? '5px 0 5px 14px' : '10px 0 12px 14px',
    '@media (max-width: 991px)': {
      padding: '10px 0',
    },
  });

export const dynamicInputValue = (hasError, isDisabled, isReadOnly, theme) =>
  css({
    color: hasError
      ? theme.errorDark
      : isDisabled
      ? theme.textLightDisabled
      : isReadOnly
      ? theme.textLightSecondary
      : theme.textLightPrimary,
    display: 'flex',
    alignItems: 'center',
    padding: '11px 9px',
    '@media (max-width: 991px)': {
      padding: '11px 9px 11px 0',
    },
  });

export const dynamicInputEditIcon = (theme) =>
  css({
    opacity: 0,
    fontSize: 20,
    padding: 3,
    borderRadius: 6,
    marginRight: 6,
    border: `1px solid ${theme.primary}`,
  });

export const dynamicDropdownClearIcon = css({
  opacity: 0,
  fontSize: 20,
  padding: 3,
  marginRight: 6,
});

export const dynamicInputValueContainer = (isDisabled, isReadOnly) =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: isReadOnly ? 'default' : isDisabled ? 'not-allowed' : 'pointer',
    outline: 'none',

    '&:hover i':
      !isDisabled && !isReadOnly
        ? {
            opacity: 1,
          }
        : {},
  });

export const dynamicInputField = (hasError, theme) =>
  css({
    borderColor: hasError ? theme.errorDark : theme.primary,
  });
// Input styles end (dynamic one)

// Dropdown styles start
export const dropdownInput = (hasError, theme) => (hasError ? css({ borderColor: theme.errorDark }) : '');
// DropDown styles end

// Dynamic dropdown styles start
export const dynamicDropdownField = (hasError, theme) =>
  css(
    {
      paddingLeft: 9,
    },
    hasError
      ? {
          borderColor: theme.errorDark,
        }
      : {},
  );

export const dynamicDropdownFieldContainer = css({
  outline: 'none',
});

export const requiredText = css({
  fontSize: '11px',
  textTransform: 'capitalize',
});

export const dynamicSwitchButtonFieldContainer = css({
  outline: 'none',
  paddingTop: 6,
});

export const rightIconsContainer = css({
  display: 'flex',
});

// Dynamic dropdown styles end

// Success Bar styles start
const successBarEffect = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

export const successBar = css({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  boxShadow: '0 -2px 4px 0 rgba(0,0,0,0.2)',
  backgroundColor: 'white',
  height: 70,
  overflow: 'hidden',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  fontSize: 18,
});

export const successBarColorEffect = (theme) =>
  css({
    width: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    backgroundColor: theme.successDark,
    animation: `${successBarEffect} 0.7s ease-in-out`,
  });

export const successBarContent = css({
  position: 'relative',
  zIndex: 10,
  width: '100%',

  '& > i': {
    position: 'absolute',
    right: '20px',
    top: '0',
    cursor: 'pointer',
  },
});
// Success Bar styles end

export const containerCheckBox = (className) =>
  css(
    {
      fontWeight: 600,
      marginBottom: 16,
    },
    className,
  );

export const phoneInputContainer = css({
  display: 'flex',
});

export const phoneInputCountrySelect = css({
  width: 100,
});

export const countryContainer = css({
  display: 'flex',
  alignItems: 'center',
  padding: 16,
  '& > .flag-img': {
    maxWidth: '24px',
    marginRight: '8px',
    border: '3px solid white',
  },
});

export const colorPickerContainer = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

export const colorPickerWrap = css({
  '& > span': {
    height: 47,
    verticalAlign: 'middle',
  },
});

export const videoPickerContainer = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

export const videoPickerWrap = css({
  'div[role=button]': {
    height: 47,
    verticalAlign: 'middle',
  },
});
