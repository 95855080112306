import { useState, useEffect } from 'react';
import { useTranslations } from '@veraio/strank';
import { useParams, Link } from 'react-router-dom';
import { Row, Col, Breadcrumbs, Icon, LatestEvents, showError } from 'components';
import { getEventDetails } from 'services';
import { getImagePath, getTranslations, getTranslationData, eventDateFormat, isEventPassed } from 'utils';
import { ReactComponent as MapPin } from 'assets/icons/map-pin-icon.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-icon.svg';
import { ReactComponent as WarningCircleIcon } from 'assets/icons/warning-circle-icon.svg';
import { header, body, separator } from './styles';

const EventDetails = () => {
  const { getText, language } = useTranslations();
  const params = useParams();
  const eventId = params?.eventId;
  const [event, setEvent] = useState();
  const { month, day } = eventDateFormat(event?.startDate, event?.endDate);
  const isExpired = isEventPassed(event?.endDate);

  const items = [
    {
      label: 'Events',
      url: '/events',
    },
    {
      label: event?.title,
    },
  ];

  useEffect(() => {
    language && fetchEvent();
  }, [eventId, language?.code]);

  const fetchEvent = async () => {
    const [res, err] = await getEventDetails(eventId, {
      expand: 'countryId',
      fields: `id,collectionId,expand,image,title,content,city,startDate,endDate,createdBy,${getTranslations(
        ['title', 'content'],
        language?.code,
      )}`,
    });
    if (err) return showError(err?.data);
    setEvent(res);
  };

  return (
    <>
      <section css={header(getImagePath(event))}>
        <div className="header-inner">
          <Breadcrumbs items={items} className="header-breadcrumbs" />
          <Link to="/events">
            <div className="go-back-to-all">
              <Icon iconName="la la-arrow-left" />
              <p>{getText('allEvents')}</p>
            </div>
          </Link>

          <Row justify="space-between" horizontalGap={16} className="content-row">
            <Col lg={6} xxxl={6}>
              {event && (
                <div className="event-content-wrap">
                  {/* <div className="event-type">
                  <span>{event?.type}</span>
                </div> */}
                  <h3 className="event-title">{getTranslationData(event, 'title', language?.code)}</h3>
                  <div className="icon-info-wrap">
                    <div className="icon-info-inner">
                      <CalendarIcon />
                      <p>{`${month}, ${day}`}</p>
                    </div>
                    <div className="icon-info-inner">
                      <MapPin />
                      <p>{`${event?.city}, ${event?.expand?.countryId?.name}`}</p>
                    </div>
                  </div>
                  <div className="separator" />
                  <div className="event-author">
                    <p>
                      <span>{getText('by')}</span> {event?.createdBy}
                    </p>
                  </div>
                  <div className="separator" />
                  {isExpired && (
                    <div className="warning-info-wrap">
                      <WarningCircleIcon />
                      <p>
                        {getText('eventPassedVisitOur')} <Link to="/events">{getText('upcomingEvents')}</Link>
                      </p>
                    </div>
                  )}
                </div>
              )}
            </Col>
            <Col lg={6} xxxl={6}>
              <div className="event-image" />
            </Col>
          </Row>
        </div>
      </section>
      <section css={body}>
        <Row container>
          <Col>
            <div
              className="event-content"
              dangerouslySetInnerHTML={{ __html: getTranslationData(event, 'content', language?.code) }}
            />
          </Col>
        </Row>
      </section>
      <div css={separator} />
      <LatestEvents titleKey="upcomingEvents" noSlider />
    </>
  );
};

export default EventDetails;
