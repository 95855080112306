export const containerClass = {
  display: 'flex',
  alignItems: 'center',
  listStyle: 'none',
  marginLeft: 0,
  padding: 0,
};
export const text =
  ({ uppercase }) =>
  (theme) => ({
    fontSize: '0.9rem',
    color: theme.secondaryDarker,
    ...(uppercase && { textTransform: 'uppercase' }),
  });

export const linkItem =
  (isActive, { uppercase }) =>
  (theme) => ({
    fontSize: '0.9rem',
    color: isActive ? theme.secondaryDarker : theme.secondary,
    textDecoration: 'underline',
    ...(uppercase && { textTransform: 'uppercase' }),
  });

export const separator = (theme) => ({
  margin: '0px 8px',
  fontSize: '0.9rem',
  color: theme.secondaryDarker,
});
