import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useNavigationControls } from '../NavigationContext';
import { contentContainer, sideNavContent } from './styles';

const Content = ({ notFoundComponent, homeRoute }) => {
  const { allRoutes } = useNavigationControls();

  return (
    <section css={contentContainer}>
      <article css={sideNavContent}>
        <Switch>
          {homeRoute && <Redirect exact from="/" to={homeRoute} />}
          {allRoutes
            .filter((route) => route.component && route.url)
            .map((route, i) => (
              <Route key={`${route.url}-${i}`} exact={route.exact} path={route.url} component={route.component} />
            ))}
          {notFoundComponent && <Route component={notFoundComponent} />}
        </Switch>
      </article>
    </section>
  );
};

Content.propTypes = {
  notFoundComponent: PropTypes.func,
  homeRoute: PropTypes.string,
};

export default Content;
