import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components';
import { getImagePath, getTranslationData, eventDateFormat } from 'utils';
import { wrap, eventImage } from './styles';

const EventBox = ({ data, className }) => {
  const { getText, language } = useTranslations();
  const { month, day } = eventDateFormat(data?.startDate, data?.endDate);

  return (
    <div css={wrap} {...(className && { className })}>
      <div className="event-image-wrap">
        <div css={eventImage(getImagePath(data))} />
        <div className="event-date-wrap">
          <p>{month}</p>
          <p>{day}</p>
        </div>
      </div>
      <div className="event-text-wrap">
        <div>
          <h6 className="event-title">{getTranslationData(data, 'title', language?.code)}</h6>
          <p className="event-text">{getTranslationData(data, 'description', language?.code)}</p>
        </div>
        <Button className="event-button" small linkTo={`/events/${data?.id}`} type="secondary">
          {getText('readMore')}
        </Button>
      </div>
    </div>
  );
};

EventBox.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
};

export default EventBox;
