export const wrap = (theme) => ({
  border: `1px solid ${theme.primary}`,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .event-image-wrap': {
    padding: '1em',
    borderBottom: `1px solid ${theme.primary}`,
    position: 'relative',
  },
  '& .event-date-wrap': {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: theme.secondaryDark,
    padding: '0.5em 1em',
    borderBottomLeftRadius: 4,
    '& > p': {
      fontSize: '0.9rem',
      lineHeight: 1.2,
      color: theme.primaryDarker,
      margin: 0,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  },
  '& .event-text-wrap': {
    height: '100%',
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .event-date': {
      fontSize: '0.8em',
      marginBottom: '0.8em',
    },
    '& .event-title': {
      marginBottom: '0.8em',
    },
    '& .event-text': {
      fontSize: '0.9em',
    },
    '& .event-button': {
      alignSelf: 'flex-start',
    },
  },
});

export const eventImage = (url) => (theme) => ({
  backgroundColor: theme.primaryDark,
  backgroundImage: `url(${url})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  aspectRatio: ' 16 / 9',
  width: '100%',
});
