export const wrap = (theme) => ({
  padding: '6em 0',
  '& .header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2em',
    '&  .title': {
      textTransform: 'uppercase',
      fontWeight: 500,
      marginBottom: '1em',
      color: theme.secondaryDarker,
      margin: 0,
    },
  },
  '@media (max-width: 991px)': {
    paddingTop: '2em',
    '&  > div ': {
      maxWidth: '100%',
      padding: '0 2em',
    },
  },
  '@media (max-width: 767px)': {
    '&  .news-box-item': {
      marginBottom: '2em',
    },
  },
});
