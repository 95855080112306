export const wrap = (theme) => ({
  '&  > div ': {
    backgroundColor: theme.secondaryDarker,
    padding: '3em 0',
    '&  .header': {
      padding: '0 4em',
      '&  .title': {
        textTransform: 'uppercase',
        maxWidth: '47.5rem',
        textAlign: 'center',
        margin: '0 auto',
        color: theme.darkText,
        fontWeight: 500,
        marginBottom: '1em',
      },
    },
  },
  '@media (max-width: 991px)': {
    '&  > div ': {
      maxWidth: '100%',
    },
  },

  '@media (max-width: 767px)': {
    '&  > div ': {
      '&  .header': {
        padding: '0 2em',
      },
    },
  },
});
