import PropTypes from 'prop-types';

const FormParagraph = (props) => {
  const { children, className } = props;
  return <div className={className}>{children}</div>;
};

FormParagraph.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

FormParagraph.displayName = 'FormParagraph';

export default FormParagraph;
