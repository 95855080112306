import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { isArray, isFunction, uniq, isNil, isString } from 'lodash-es';
import { Icon } from '../Icon';
import { getFormChilds } from './utils';
import { getNestedProperty } from '../../utils';
import { successBar, successBarColorEffect, successBarContent } from './styles';

const SuccessBar = ({ onHide, message }) => {
  const theme = useTheme();

  return (
    <div css={successBar}>
      <div css={successBarColorEffect(theme)} />
      <div css={successBarContent}>
        {isArray(message) ? `Successfully edited: ${message.join(', ')}` : message}
        <Icon material color="secondary" iconName="close" onClick={onHide} />
      </div>
    </div>
  );
};

SuccessBar.propTypes = {
  onHide: PropTypes.func,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export const useSuccessBar = () => {
  const [isVisible, setIsVisible] = useState(null);
  const formRef = useRef({});
  const messageToShow = useRef([]);

  useEffect(() => {
    if (!isVisible) return () => {};

    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [isVisible]);

  const hideSuccessBar = () => setIsVisible(false);

  const setUpMessageAndShow = (val) => {
    messageToShow.current = val;
    setIsVisible(true);
  };

  const showSuccessBar = (values, mapFunction) => {
    if (isString(values)) {
      setUpMessageAndShow(values);
      return;
    }

    const { children } = formRef.current;
    const childrens = getFormChilds(children);

    const editedChildrens = childrens
      // Filter those children which do not have a representative value inside the form object
      .filter((el) => el.props.id && !isNil(getNestedProperty(values, el.props.id.split('.'))))
      // Map the fields that have values to their labels or pass the child.id and child to map function
      .map((child) => (isFunction(mapFunction) ? mapFunction(child.props.id, child) : child.props.label));

    // Save edited fields by removing the duplicates
    setUpMessageAndShow(uniq(editedChildrens));
  };

  return {
    isVisible,
    hideSuccessBar,
    showSuccessBar,
    formRef,
    SuccessBar: (formProps) => <SuccessBar onHide={hideSuccessBar} message={messageToShow.current} {...formProps} />,
  };
};

export default SuccessBar;
