import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { isFunction } from 'lodash-es';
import { checkButtonContainer, checkButtonIndicator, checkButtonSvg } from './styles';

const CheckButton = ({ label, value, onChange, size = 24, className, inverse }) => {
  const theme = useTheme();
  const [isChecked, setIsChecked] = useState(!!value);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  const handleChange = () => {
    setIsChecked((prev) => {
      isFunction(onChange) && onChange(!prev);
      return !prev;
    });
  };

  return (
    <div
      role="checkbox"
      tabIndex={0}
      css={checkButtonContainer(!!label)}
      className={className}
      onClick={handleChange}
      aria-checked={isChecked}>
      <span css={checkButtonIndicator(isChecked, size, inverse, theme)} className="input-indicator" />
      <svg css={checkButtonSvg(isChecked, size, inverse, theme)} viewBox="0 0 21 21">
        {inverse ? (
          <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186" />
        ) : (
          <polyline points="5 10.75 8.5 14.25 16 6" />
        )}
      </svg>
      <span className="label">{label}</span>
    </div>
  );
};

CheckButton.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.number,
  inverse: PropTypes.bool,
  label: PropTypes.any,
};

export default CheckButton;
