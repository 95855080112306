import { hexToRgb } from '../../utils';

export const inputField = (hasText) => (theme) => {
  const rgb = hexToRgb(theme.primary);

  return {
    color: theme[hasText ? 'textLightPrimary' : 'textLightDisabled'],
    border: `1px solid ${theme.border}`,
    borderRadius: '0.5rem',
    padding: '1rem',
    fontSize: '1rem',
    lineHeight: 1.2,
    width: '100%',
    maxWidth: '100%',
    transition: 'box-shadow .2s ease-in-out, border-color .2s ease-in-out',

    '&:focus, &:active': {
      outline: 'none',
      // borderColor: theme.primary,
      boxShadow: `0 0 0 0.2rem rgb(${rgb.r} ${rgb.g} ${rgb.b} / 25%)`,
    },
  };
};
