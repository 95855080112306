/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavLinks from '../NavLinks';
import MobileNav from '../MobileNav';
import Languages from '../Languages';
import { useWindowSize } from '../../hooks';
import companyLogoImg from '../../../assets/nav-image/oneEcoSystemLogo.svg';
import { navBarHeaderContainer, navLogoWrap } from './styles';

const NavBar = () => {
  const windowWidth = useWindowSize(window.innerWidth, window.innerHeight);
  const [isMobile, setIsMobile] = useState();

  useEffect(() => {
    setIsMobile(windowWidth.width <= 767);
  }, [windowWidth.width]);

  return (
    <header css={navBarHeaderContainer} className="container-fluid">
      <Link to="/">
        <img src={companyLogoImg} alt="Logo" css={navLogoWrap} />
      </Link>
      {isMobile ? (
        <MobileNav />
      ) : (
        <>
          <NavLinks />
          <Languages />
        </>
      )}
    </header>
  );
};

export default NavBar;
