import { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { checkButtonContainerBase, switchToggle } from './styles';

const SwitchButton = ({ label, className, value = false, onChange = () => {}, size = 24 }) => {
  const theme = useTheme();
  const [isChecked, setIsChecked] = useState(!!value);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  const handleChange = () =>
    setIsChecked((prev) => {
      onChange(!prev);
      return !prev;
    });

  return (
    <div
      role="checkbox"
      tabIndex={0}
      css={checkButtonContainerBase(!!label)}
      className={className}
      onClick={handleChange}
      aria-checked={isChecked}>
      <span
        css={switchToggle(
          {
            width: size,
            smallWidth: size - size / 4,
            padding: size / 8,
          },
          isChecked,
          theme,
        )}
        className="input-indicator"
      />
      <span className="label">{label}</span>
    </div>
  );
};

SwitchButton.propTypes = {
  label: PropTypes.any,
  className: PropTypes.object,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.number,
};

export default SwitchButton;
