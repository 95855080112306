import { useTranslations } from '@veraio/strank';
import { Row, Col, Button } from 'components';
import { networkTypes } from 'enums';
import { header, body, networkBox } from './styles';

const Network = () => {
  const { getText } = useTranslations();

  return (
    <>
      <section css={header}>
        <Row container>
          <Col sm={12}>
            <h2 data-strank-key="ecosystemNetworkStructure">{getText('ecosystemNetworkStructure')}</h2>
            <p data-strank-key="networkPageSubtitle">{getText('networkPageSubtitle')}</p>
          </Col>
        </Row>
      </section>
      <div css={body}>
        <Row container>
          <Col sm={12}>
            {networkTypes?.map((el) => (
              <div key={el?.id} css={networkBox(el?.image)}>
                {/* <div className="network-bg-image" /> */}
                <img src={el?.image} alt={`${el?.nameKey}-img`} className="network-img" />
                <h3>{getText(el.nameKey)}</h3>
                <p data-strank-key={el.textKey}>{getText(el.textKey)}</p>
                <Button type="secondary" arrow linkTo={`/network/${el.linkTo}`}>
                  {getText(el.buttonTextKey)}
                </Button>
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Network;
