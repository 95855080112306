export const navBarBurgerIcon = (open) => (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '2em',
  borderLeft: `1px solid ${theme.primary}`,
  '& .line': {
    width: 20,
    height: 2,
    marginBottom: open ? '4.5px' : 6,
    backgroundColor: theme.primaryText,
    transformOrigin: 1,
    borderRadius: 2,
    transition: 'all 0.3s linear',
    '&:nth-of-type(1)': {
      transform: open ? 'rotate(45deg)' : 'rotate(0deg)',
    },
    '&:nth-of-type(2)': {
      transform: open ? 'translateX(100%)' : 'rotate(0)',
      opacity: open ? 0 : 1,
    },
    '&:nth-of-type(3)': {
      transform: open ? 'rotate(-45deg)' : 'rotate(0deg)',
      marginBottom: 0,
    },
  },
});

export const tooltipContainer = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'fixed',
  top: '4rem',
  bottom: 0,
  right: 0,
  left: 0,
  backgroundColor: theme.primaryDarker,
});

export const navLinksWrap = {
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100%',
  overflowX: 'auto',
  '::-webkit-scrollbar': {
    display: 'none',
  },
};

export const navLinkStyle = (theme) => ({
  color: theme.secondaryDarker,
  padding: '1.5em 1em',
  borderBottom: `1px solid ${theme.primary}`,
  '&.active': {
    color: theme.secondary,
  },
  '&:hover': {
    color: theme.secondaryDark,
  },
});
