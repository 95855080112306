export const header = (theme) => ({
  padding: '2em 4em',
  '& .go-back-to-all': {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      margin: 0,
      textTransform: 'uppercase',
      color: theme.secondaryDark,
      fontSize: '1.2rem',
    },
    '& > i': {
      marginRight: '0.2em',
      color: theme.secondaryDark,
    },
    '&:hover': {
      '& > p': {
        color: theme.secondary,
      },
      '& > i': {
        color: theme.secondary,
      },
    },
  },
  '@media (max-width: 991px)': {
    padding: '2em',
    '&  > div ': {
      maxWidth: '100%',
    },
  },
});

export const body = (theme) => ({
  padding: '0 4em',
  marginBottom: '2em',
  '&  .country-box ': {
    border: `1px solid ${theme.primary}`,
    height: 'calc(100% - 1em)',
    marginRight: '1em',
    padding: '1em',
    '&  > img ': {
      width: '100%',
      height: 'auto',
      marginBottom: '0.5em',
    },
    '&  > h5 ': {
      textAlign: 'center',
      marginBottom: '0.5em',
    },
  },
  '&  .col-item ': {
    marginBottom: '1em',
  },
  '@media (max-width: 991px)': {
    padding: '0 2em',
    '&  > div ': {
      maxWidth: '100%',
    },
  },
  '@media (max-width: 767px)': {
    '&  .country-box ': {
      border: 0,
      padding: 0,
      marginRight: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '&  > img ': {
        maxWidth: '25rem',
      },
      '&  > h4 ': {
        marginBottom: '1em',
      },
    },
  },
});

export const managerBox = (avatar) => (theme) => ({
  border: `1px solid ${theme.primary}`,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  padding: '1em',
  '& .box-header': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .avatar-image': {
      backgroundColor: theme.primaryDark,
      backgroundImage: `url(${avatar})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '8rem',
      height: '8rem',
      borderRadius: '50%',
      marginBottom: '0.5em',
    },
    '& > h6': {
      textAlign: 'center',
      marginBottom: '0.5em',
    },
    '& > p': {
      textAlign: 'center',
      color: theme.secondaryDarker,
      fontSize: '0.8rem',
      marginBottom: '2em',
    },
  },
  '& .manager-contact-button': {
    border: `1px solid ${theme.primary}`,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '1em',
    '&  svg ': {
      width: '1.5rem',
      marginRight: '0.5em',
    },
    '& > p ': {
      flex: '1 0 0%',
      color: theme.secondaryDark,
      margin: 0,
      fontSize: '0.9rem',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
});

export const footer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '3em 0',
};
