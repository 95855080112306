/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { isNil } from 'lodash-es';
import { Row, Col, Button, useWindowSize, EventBox, showError, useUrlParams } from 'components';
import { getEvents } from 'services';
import { filterModel, getTranslations } from 'utils';
import { wrap, listWrap, footer } from './styles';

const EventsList = () => {
  const { getText, language } = useTranslations();
  const { urlQueryParams } = useUrlParams();
  const { preview } = urlQueryParams;
  const windowWidth = useWindowSize(window.innerWidth, window.innerHeight);
  const [events, setEvents] = useState(null);
  const bufferNumber = windowWidth.width <= 767 ? 1 : windowWidth.width <= 991 ? 2 : 3;

  useEffect(() => {
    language && fetchEvents(1);
  }, [language?.code]);

  const fetchEvents = async (page) => {
    const filter = filterModel({
      ...(!preview && { isActive: 'isActive=true' }),
    });

    const [res, err] = await getEvents({
      page,
      perPage: 20,
      sort: '-startDate',
      filter,
      fields: `id,collectionId,image,title,description,startDate,endDate,${getTranslations(
        ['title', 'description'],
        language?.code,
      )}`,
    });
    if (err) return showError(err?.data);

    setEvents((prev) =>
      isNil(prev) || page === 1
        ? res
        : {
            ...res,
            items: [...prev.items, ...res.items],
          },
    );
  };

  const handleMore = () => {
    const nextPage = events?.page + 1;
    fetchEvents(nextPage);
  };

  return (
    <section css={wrap}>
      <h4>{getText('latestEvents')}</h4>
      <div css={listWrap}>
        <Row>
          {events?.items?.map((el, i) => (
            <Fragment key={el?.id}>
              <Col md={6} lg={4}>
                <EventBox data={el} />
              </Col>
              {(i + 1) % bufferNumber === 0 && (
                <Col md={12} lg={12}>
                  <div className="list-buffer" />
                </Col>
              )}
            </Fragment>
          ))}
        </Row>
      </div>

      <div css={footer}>
        {events?.page < events?.totalPages && (
          <Button onClick={handleMore} type="gray">
            {getText('moreEvents')}
          </Button>
        )}
      </div>
    </section>
  );
};

export default EventsList;
