export const header = (theme) => ({
  display: 'flex',
  '& > .product-col': {
    flex: '0 0 auto',
    width: '50%',
  },
  '& > .left-side': {
    backgroundColor: theme.primaryDark,
    display: 'flex',
    alignItems: 'center',
    padding: '0 4em 0 4em',
    '& .label ': {
      borderRadius: 3,
      backgroundColor: theme.label,
      display: 'inline-block',
      fontSize: '0.8rem',
      padding: '0.4em',
      textTransform: 'uppercase',
      marginBottom: '0.5em',
    },
    '& h1': {
      color: theme.secondary,
      marginBottom: '0.2em',
      textTransform: 'uppercase',
    },
    '& p': {
      fontSize: '1.2rem',
      textTransform: 'uppercase',
      '&:last-of-type': {
        marginBottom: '3em',
      },
    },
  },

  '& > .video-side': {
    backgroundColor: theme.primaryDark,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '@media (max-width: 1400px)': {
    '& > .left-side': {
      '& h1': {
        fontSize: '3rem',
      },
      '& p': {
        fontSize: '1rem',
      },
    },
  },

  '@media (max-width: 991px)': {
    flexDirection: 'column-reverse',
    '& > .product-col': {
      width: '100%',
    },
    '& > .left-side': {
      padding: '4em 2em',
    },
  },
});

export const video = {
  width: '100%',
  height: 'auto',
  marginBottom: 0,
};

export const body = (theme) => ({
  padding: '0 4em',
  '& > .body-buffer': {
    position: 'relative',
    height: 100,
    '&:before': {
      content: '""',
      width: 2,
      backgroundColor: theme.secondaryDarker,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 'calc(50% + 1px) ',
    },
  },
  '& > .content-holder': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '130px 0',
    '& > .content-inner': {
      backgroundColor: theme.primaryDarker,
      position: 'relative',
      padding: '2em 6em',
      border: `2px solid ${theme.secondaryDarker}`,
      textAlign: 'center',
      width: '40rem',
      height: '40rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 0,
      '&:before': {
        content: '""',
        width: '40rem',
        height: '40rem',
        border: `2px solid ${theme.secondaryDarker}`,
        position: 'absolute',
        top: 0,
        left: 0,
        transform: 'rotate(45deg)',
        zIndex: -1,
      },
      '& > .description': {
        height: '24rem',
        overflowX: 'auto',
        paddingRight: '1em',
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          borderRadius: '10px',
          backgroundColor: theme.darkOverlay,
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: theme.secondaryDarker,
          borderRadius: '10px',
        },
        '::-webkit-scrollbar-thumb:hover': {
          display: 'none',
        },
      },
    },
  },

  '@media (max-width: 991px)': {
    '& > .content-holder': {
      padding: '105px 0',
    },
  },
  '@media (max-width: 767px)': {
    padding: 0,
    '& > .body-buffer': {
      display: 'none',
    },
    '& > .content-holder': {
      padding: 0,
      display: 'block',
      '& > .content-inner': {
        padding: '4em 2em',
        textAlign: 'left',
        display: 'block',
        border: 0,
        height: 'auto',
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '& > .description': {
          height: '100%',
          paddingRight: 0,
        },
      },
    },
  },
});

export const footer = (theme) => ({
  backgroundColor: theme.secondary,
  padding: '4em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& > .left-side': {
    display: 'flex',
    alignItems: 'center',
    '& > .image-holder': {
      maxWidth: '6rem',
      marginRight: '2em',
      '& > .product-image': {
        width: '100%',
        height: 'auto',
      },
    },
    '& > h3': {
      color: theme.primaryDarker,
      textTransform: 'uppercase',
    },
  },
  '@media (max-width: 767px)': {
    padding: '2em',
    flexDirection: 'column',
    '& > .left-side': {
      marginBottom: '1em',
    },
    '& > .footer-button': {
      display: 'block',
      width: '100%',
    },
  },
});
