export const navLinksWrap = {
  display: 'flex',
  alignItems: 'center',
};

export const navLinkStyle = (theme) => ({
  color: theme.secondaryDarker,
  fontSize: '0.9rem',
  margin: '0 1em',
  padding: '1.2em 0.5em',
  '&.active': {
    color: theme.secondary,
    borderBottom: `2px solid ${theme.secondaryDarker}`,
  },
  '&:hover': {
    color: theme.secondaryDark,
  },
});
