import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import moment from 'moment';
import {
  Row,
  Col,
  ArrowLink,
  showError,
  // Skeleton
} from 'components';
import { ReactComponent as MapPin } from 'assets/icons/map-pin-icon.svg';
import { getEvents } from 'services';
import { eventDateFormat, getImagePath, filterModel } from 'utils';
import { wrap, eventWrap, eventImage } from './styles';

const UpcomingEvents = () => {
  const { getText } = useTranslations();
  const [event, setNews] = useState();

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    const filter = filterModel({
      isActive: 'isActive=true',
      startDate: `startDate > '${moment().format('YYYY-MM-DD')}'`,
    });

    const [res, err] = await getEvents({
      perPage: 3,
      sort: '-startDate',
      filter,
      expand: 'countryId',
      fields: 'id,collectionId,image,title,description,startDate,endDate,city,expand.countryId.name',
      skipTotal: true,
    });
    if (err) return showError(err?.data);
    setNews(res);
  };

  return (
    event?.items?.length > 0 && (
      <div css={wrap}>
        <Row container>
          <Col sm={12}>
            <div className="header">
              <h4 className="title">{getText('upcomingEvents')}</h4>
              <ArrowLink title={getText('seeAll')} linkTo={'/'} />
            </div>
          </Col>
        </Row>
        {/* {!event && (
        <Row container horizontalGap={8} css={eventWrap}>
          <Col>
            <Skeleton count={3} height={'10rem'} />
          </Col>
        </Row>
      )} */}
        {event?.items?.map((el) => {
          const { month, day } = eventDateFormat(el?.startDate, el?.endDate);
          return (
            <Row container horizontalGap={8} css={eventWrap} key={el?.id}>
              <Col md="auto">
                <div className="date-wrap">
                  <p>{month}</p>
                  <p>{day}</p>
                </div>
              </Col>
              <Col md>
                <div className="event-data-wrap">
                  <Row horizontalGap={16}>
                    <Col md="auto">
                      <div css={eventImage(getImagePath(el))} />
                    </Col>
                    <Col md>
                      <div className="event-data">
                        {/* <div className="label">
                        <span>{el?.type}</span>
                      </div> */}
                        <h6>{el?.title}</h6>
                        <p>{el?.description}</p>
                        <div className="location-info-wrap">
                          <div className="location-info">
                            <MapPin />
                            <p>{`${el?.city}, ${el?.expand?.countryId?.name}`}</p>
                          </div>
                          <ArrowLink title={getText('viewEvent')} size="small" linkTo={`/events/${el?.id}`} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          );
        })}
      </div>
    )
  );
};

export default UpcomingEvents;
