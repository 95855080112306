import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { isArray } from 'lodash-es';
import { Icon } from '../../Icon';
import Collapse from '../../Collapse';
import { menuItem, menuItemText, subMenuItem, subMenuCollapse } from './styles';

export const MenuItem = ({ label, icon, url, iconProps, onSelect, subMenus = [] }) => {
  const hasSubMenus = isArray(subMenus) && !!subMenus.length;

  const handleMouseEnter = () => onSelect(subMenus);

  const handleClick = (e) => {
    hasSubMenus && e.preventDefault();
    onSelect(subMenus);
  };

  return (
    <NavLink to={url} css={menuItem(hasSubMenus)} onMouseEnter={handleMouseEnter} onClick={handleClick}>
      <Icon size={36} iconName={icon} {...iconProps} />
      <span css={menuItemText}>{label}</span>
      <div className="menuItemIndicator" />
    </NavLink>
  );
};

MenuItem.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  iconProps: PropTypes.object,
  onSelect: PropTypes.func,
  subMenus: PropTypes.array,
};

export const MenuSubItem = ({ label, icon, url, iconProps, subMenus = [] }) =>
  subMenus?.length ? (
    <Collapse
      header={
        <div css={subMenuItem()} className="submenu-collapse-header">
          <Icon size={18} iconName={icon} {...iconProps} />
          <span css={menuItemText}>{label}</span>
        </div>
      }
      css={subMenuCollapse}>
      {subMenus.map((subMenu, ind) => (
        <NavLink key={`${subMenu.url}-${ind}`} to={subMenu.url} css={subMenuItem({ paddingLeft: '3rem' })}>
          <Icon size={18} iconName={subMenu.icon} {...subMenu.iconProps} />
          <span css={menuItemText}>{subMenu.label}</span>
        </NavLink>
      ))}
    </Collapse>
  ) : (
    <NavLink to={url} css={subMenuItem()}>
      <Icon size={18} iconName={icon} {...iconProps} />
      <span css={menuItemText}>{label}</span>
    </NavLink>
  );

MenuSubItem.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  iconProps: PropTypes.object,
  onSelect: PropTypes.func,
  subMenus: PropTypes.array,
};
