import { getReq } from './axios/makeRequest';
import { getPocketBaseParams } from './models/pocketbase';

const baseUrl = `${pocketbaseUrl}/api/collections/countryManagers/records`;

export const getCountryManagers = (options = {}) => {
  const params = getPocketBaseParams(options);
  return getReq(`${baseUrl}?${params}`);
};

export const getCountryManagersDetails = (id, options = {}) => {
  const params = getPocketBaseParams(options);
  return getReq(`${baseUrl}/${id}?${params}`);
};
