import { useState, useRef, useContext, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';

const NavigationContext = createContext({});
export const useNavigationControls = () => useContext(NavigationContext);

export const NavigationProvider = (props) => {
  const { children, closeBreakPoint = 992 } = props;
  const [isOpen, setIsOpen] = useState(window.innerWidth >= closeBreakPoint);
  const [breakPoint, setBreakPoint] = useState(closeBreakPoint);
  const [allRoutes, setAllRoutes] = useState([]);
  const previousWidthRef = useRef(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setBreakPoint(closeBreakPoint);
    setIsOpen(window.innerWidth >= closeBreakPoint);
  }, [closeBreakPoint]);

  const handleWindowResize = debounce((e) => {
    if (e.currentTarget.innerWidth < breakPoint && previousWidthRef.current >= breakPoint) setIsOpen(false);
    else if (e.currentTarget.innerWidth >= breakPoint && previousWidthRef.current < breakPoint) setIsOpen(true);
    previousWidthRef.current = e.currentTarget.innerWidth;
  }, 500);

  return (
    <NavigationContext.Provider
      value={{
        isOpen,
        close: () => setIsOpen(false),
        open: () => setIsOpen(true),
        toggle: () => setIsOpen((prev) => !prev),
        changeBreakPoint: (val) => setBreakPoint(val),
        allRoutes,
        changeAllRoutes: setAllRoutes,
      }}>
      {children}
    </NavigationContext.Provider>
  );
};

NavigationProvider.propTypes = {
  children: PropTypes.node,
  closeBreakPoint: PropTypes.number,
};
