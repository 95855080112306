export const wrap = (theme) => ({
  backgroundColor: theme.secondaryDarker,
  padding: '3em 5em',
  '& .content-subtitle': {
    textTransform: 'uppercase',
    color: theme.secondary,
    fontSize: '1.1rem',
    marginBottom: '0.3em',
  },
  '& .content-title': {
    textTransform: 'uppercase',
    color: theme.darkText,
    marginBottom: '0.3em',
  },
  '& .content-text': {
    color: theme.darkText,
  },
  '& .product-image': {
    maxWidth: '100%',
    height: 'auto',
  },
  '@media (max-width: 991px)': {
    padding: '2em 4em',
    '& > div': {
      flexDirection: 'column-reverse',
    },
    '& .content-title': {
      display: 'none',
    },
    '& .product-image-wrap': {
      textAlign: 'center',
      marginBottom: '0.5em',
    },
  },
  '@media (max-width: 767px)': {
    padding: '2em',
  },
});
