import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { isFunction } from 'lodash-es';
import { tooltipContainer, popupTooltip } from './styles';

const Tooltip = (props) => {
  const { children, content, onClick, open, style } = props;
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(open);

  const handleClick = () => onClick && setIsOpen((prev) => !prev);

  const handleBlur = () => onClick && setIsOpen(false);

  return (
    <div
      role="presentation"
      onClick={handleClick}
      onBlur={handleBlur}
      css={tooltipContainer(props, isOpen)}
      style={style}>
      <div css={popupTooltip(props, theme)}>{isFunction(content) ? content() : content}</div>
      {children}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.any,
  content: PropTypes.any,
  open: PropTypes.bool,
  onClick: PropTypes.bool,
  style: PropTypes.object,
};

export default Tooltip;
