export const wrap = (theme) => ({
  '& > h4': {
    textTransform: 'uppercase',
    textAlign: 'center',
    margin: '0.5em 0',
    color: theme.secondaryDarker,
  },

  '@media (max-width: 767px)': {
    padding: '0 2em',
  },
});

export const scrollButtonsWrap = (theme) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '0.5em',
  '& > .scroll-button': {
    border: `1px solid ${theme.primary}`,
    width: '2rem',
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color .15s ease-in-out',
    '& > svg': {
      width: '0.6rem',
    },
    '&:hover': {
      backgroundColor: theme.secondaryDarker,
    },
  },
});

export const tabsWrap = {
  display: 'flex',
  overflowX: 'scroll',
  scrollBehavior: 'smooth',
  marginBottom: '2em',
  '::-webkit-scrollbar': {
    display: 'none',
  },
};

export const listWrap = {
  '& .list-buffer': {
    height: '2rem',
  },
};

export const footer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '3em 0',
};
