import queryString from 'query-string';

export const getPocketBaseParams = (options) =>
  queryString.stringify(
    {
      ...(options?.page && { page: options.page }),
      ...(options?.perPage && { perPage: options.perPage }),
      ...(options?.sort && { sort: options.sort }),
      ...(options?.filter && { filter: options.filter }),
      ...(options?.expand && { expand: options.expand }),
      ...(options?.fields && { fields: options.fields }),
      ...(options?.skipTotal && { skipTotal: options.skipTotal }),
    },
    { arrayFormat: 'index' },
  );
