export default class {
  constructor(callback, delay) {
    this.timerId = null;
    this.startTime = 0;
    this.remaining = delay;
    this.callback = callback;
  }

  start() {
    this.startTime = Date.now();
    this.timerId = setTimeout(this.callback, this.remaining);
  }

  pause() {
    clearTimeout(this.timerId);
    this.remaining -= Date.now() - this.startTime;
  }

  clear() {
    clearTimeout(this.timerId);
  }
}
