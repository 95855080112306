import { useRef, useState, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { isBoolean, isArray, pick, isEmpty, isFunction, isNil } from 'lodash-es';
import qs from 'query-string';
import { Route, Redirect, useHistory, Switch } from 'react-router-dom';
import { Icon, useWindowSize } from 'components';
import { fadeInAnimation } from '../styles';
import { tabs, tabsListContainer, arrowsWrap } from './styles';

const Tabs = ({
  children: initChildren,
  startingRoute,
  routeMatch,
  tabsContentClass,
  sharedQueryParams,
  pills,
  vertical,
  className,
  onChange,
  noAnimation,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const windowWidth = useWindowSize(window.innerWidth, window.innerHeight);
  const { location } = history;
  const children = Children.toArray(initChildren).filter(Boolean);
  const hasRoutes = children.every((el) => el.props?.url);
  const [activeTab, setActiveTab] = useState(children.length && children[0]?.props?.label);
  const contentContainerRef = useRef();
  if (!children.length) return null;
  const isMobile = windowWidth.width <= 991;

  const parsedQueryParams = qs.parse(location.search);
  const tabQueryParams = isBoolean(sharedQueryParams)
    ? parsedQueryParams
    : isArray(sharedQueryParams)
    ? pick(parsedQueryParams, sharedQueryParams)
    : {};
  const stringifiedQueryParams = isEmpty(tabQueryParams) ? '' : `?${qs.stringify(tabQueryParams)}`;
  const defaultUrl = `${startingRoute}${children[0]?.props?.url}${stringifiedQueryParams}`;

  const handleTabChange = (tab) => {
    isFunction(onChange) && onChange(tab);
    setActiveTab(tab);
    const contentNode = contentContainerRef.current;
    if (!noAnimation && contentNode.getAttribute('data-active-tab') !== tab) {
      contentNode.setAttribute('data-active-tab', tab);

      contentNode.style.animation = 'none';
      setTimeout(() => {
        contentNode.style.animation = '';
      });
    }
  };

  const handleTabChangeArrow = (isRight) => {
    const currentIndex = children.findIndex((el) => el?.props?.label === activeTabChild?.props?.label);

    if ((currentIndex === 0 && !isRight) || (currentIndex >= children?.length && isRight)) return;

    const selectTab = children?.at(isRight ? currentIndex + 1 : currentIndex - 1);
    selectTab && handleTabChange(selectTab?.props?.label);
  };

  const renderRouteChilds = children.map((child) => {
    const { label, url, component } = child.props;
    return <Route exact key={label} path={`${routeMatch ?? startingRoute}${url}`} render={component} />;
  });

  const activeTabChild = children.find((el) => el.props.label === activeTab);
  const ActiveComponent = activeTabChild?.props.component;

  return (
    <div
      css={tabs(vertical, isNil(activeTabChild?.props?.withPadding) || activeTabChild?.props?.withPadding)}
      className={className}>
      <div className="tabs-list-container" role="tablist" css={tabsListContainer(pills, vertical, theme)}>
        {!isMobile ? (
          <>
            {children.map((child) =>
              cloneElement(child, {
                key: child.props.label,
                active: activeTab === child.props.label,
                onClick: handleTabChange,
                hasRoutes,
                url: `${startingRoute}${child.props.url}${stringifiedQueryParams}`,
                pills,
                vertical,
              }),
            )}
          </>
        ) : (
          <>
            <div css={arrowsWrap(false)}>
              <Icon iconName="la la-angle-left" onClick={() => handleTabChangeArrow(false)} />
            </div>
            {cloneElement(activeTabChild, {
              key: activeTabChild.props.label,
              active: activeTab === activeTabChild.props.label,
              hasRoutes,
              url: `${startingRoute}${activeTabChild.props.url}${stringifiedQueryParams}`,
              pills,
              vertical,
            })}
            <div css={arrowsWrap(true)}>
              <Icon iconName="la la-angle-right" onClick={() => handleTabChangeArrow(true)} />
            </div>
          </>
        )}
      </div>
      <div ref={contentContainerRef} className="tabs-content" css={[!noAnimation && fadeInAnimation, tabsContentClass]}>
        {hasRoutes ? (
          <Switch>
            {renderRouteChilds}
            <Redirect from={startingRoute} to={defaultUrl} />
          </Switch>
        ) : (
          <ActiveComponent />
        )}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  startingRoute: PropTypes.string,
  routeMatch: PropTypes.string,
  children: PropTypes.instanceOf(Array),
  tabsContentClass: PropTypes.object,
  sharedQueryParams: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  pills: PropTypes.bool,
  vertical: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  noAnimation: PropTypes.bool,
};

export default Tabs;
