export const modalWrap = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
};

export const modalInner = (theme) => ({
  position: 'relative',
  backgroundColor: theme.primaryDark,
  width: '100%',
  height: 'auto',
  maxHeight: '100%',
  overflowX: 'auto',
  paddingTop: '4em',
  borderBottom: `1px solid ${theme.secondaryDarker}`,
});

export const closeIcon = (theme) => ({
  position: 'absolute',
  top: '5rem',
  right: '1em',
  color: theme.secondaryDarker,
  '&:hover': {
    color: theme.secondaryDark,
  },
});

export const navLinkStyle = (isActive) => (theme) => ({
  color: theme.secondaryDarker,
  padding: '1.5em 1em',
  borderBottom: `1px solid ${theme.primary}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  ...(isActive && {
    color: theme.secondary,
  }),
  '&:hover': {
    color: theme.secondaryDark,
  },
});

export const navIcon = {
  width: '1.4rem',
  marginLeft: '0.5em',
};
