import { NavLink } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import NavDropdown from '../NavDropdown';
import { ProductsNavDropdown } from '../../screens';
import { navLinksWrap, navLinkStyle } from './styles';

const NavLinks = () => {
  const { getText } = useTranslations();

  return (
    <>
      <div css={navLinksWrap}>
        <NavDropdown name="products" content={<ProductsNavDropdown />} />
        <NavLink to="/network" role="button" tabIndex={0} css={navLinkStyle}>
          {getText('network')}
        </NavLink>
        <NavLink to="/news" role="button" tabIndex={0} css={navLinkStyle}>
          {getText('news')}
        </NavLink>
        <NavLink to="/events" role="button" tabIndex={0} css={navLinkStyle}>
          {getText('events')}
        </NavLink>
      </div>
    </>
  );
};

export default NavLinks;
