import { useState, useEffect } from 'react';
import { Row, Col } from 'components';
import { useTranslations } from '@veraio/strank';
import { ProductsTabs, UpcomingEvents, LatestNews, useEventListener } from 'components';
import videoWeb from 'assets/videos/intro.mp4';
import videoMobile from 'assets/videos/intro-mobile.mp4';
// import { ReactComponent as OneLogo } from 'assets/images/one-logo.svg';
import { ReactComponent as BulletArrow } from 'assets/icons/bullet-arrow.svg';
// import { ReactComponent as GoToArrow } from 'assets/icons/go-to-icon.svg';
import visionHomeImage from 'assets/images/vision-home-img.jpg';
import opportunitiesHomeImage from 'assets/images/opportunities-home-img.jpg';
import {
  homeSectionOneVideo,
  video,
  // homeSectionOne,
  homeSectionTwo,
  homeSectionThree,
  sectionTreeImageBox,
  // goToButton
} from './styles';

const Home = () => {
  const { getText } = useTranslations();
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [showLinks, setShowLinks] = useState(false);

  useEventListener('resize', () => {
    setWindowSize(window.innerWidth);
  });

  useEffect(() => {
    const introVideo = document.getElementById('videoId');
    introVideo.addEventListener('ended', videoHandler, false);

    // cleanup this component
    return () => {
      introVideo.removeEventListener('ended', videoHandler, false);
    };
  }, []);

  const videoHandler = () => setShowLinks(true);

  return (
    <>
      {/* <section css={homeSectionOne}>
        <div className="inner-intro">
          <Row container align="center" className="main-row">
            <Col lg>
              <div className="left-side">
                <h4>One ecosystem</h4>
                <h1 data-strank-key="shapeTheFuture">{getText('shapeTheFuture')}</h1>
              </div>
            </Col>
            <Col xs={4} sm={4} lg={4}>
              <OneLogo className="one-logo" />
            </Col>
          </Row>
        </div>
      </section> */}
      <section css={homeSectionOneVideo(showLinks)}>
        <video id="videoId" playsInline css={video} autoPlay muted>
          <source src={windowSize < 767 ? videoMobile : videoWeb} alt="OneEcoSystem-intro-video" type="video/mp4" />
        </video>
        <div className="intro-text-wrap">
          <div className="intro-text-inner">
            <Row container align="center">
              <Col md={'auto'}>
                <div className="left-side">
                  <h4>One ecosystem</h4>
                  <h1 data-strank-key="shapeTheFuture">{getText('shapeTheFuture')}</h1>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <section css={homeSectionTwo}>
        <Row container justify="space-between">
          <Col md={6}>
            <div className="left-side">
              <h2 data-strank-key="homeSectionTwoTitle">{getText('homeSectionTwoTitle')}</h2>
              <p data-strank-key="homeSectionTwoText">{getText('homeSectionTwoText')}</p>
            </div>
          </Col>

          <Col md={5}>
            <div className="right-side">
              <div className="item">
                <BulletArrow />
                <div>
                  <h5 data-strank-key="homeSectionTwoBulletTitleOne">{getText('homeSectionTwoBulletTitleOne')}</h5>
                  <p data-strank-key="homeSectionTwoBulletTextOne">{getText('homeSectionTwoBulletTextOne')}</p>
                </div>
              </div>
              <div className="item">
                <BulletArrow />
                <div>
                  <h5 data-strank-key="homeSectionTwoBulletTitleTwo">{getText('homeSectionTwoBulletTitleTwo')}</h5>
                  <p data-strank-key="homeSectionTwoBulletTextTwo">{getText('homeSectionTwoBulletTextTwo')}</p>
                </div>
              </div>
              <div className="item">
                <BulletArrow />
                <div>
                  <h5 data-strank-key="homeSectionTwoBulletTitleThree">{getText('homeSectionTwoBulletTitleThree')}</h5>
                  <p data-strank-key="homeSectionTwoBulletTextThree">{getText('homeSectionTwoBulletTextThree')}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <ProductsTabs />
      <section css={homeSectionThree}>
        <Row container>
          <Col md={6}>
            <div className="section-tree-item-text">
              <h3>{getText('vision')}</h3>
              <p data-strank-key="visionShortText">{getText('visionShortText')}</p>
            </div>
            {/* <div role="button" tabIndex={0} css={goToButton}>
              <p>{getText('readMore')}</p>
              <GoToArrow />
            </div> */}
          </Col>
          <Col md={6}>
            <div css={sectionTreeImageBox(visionHomeImage)} />
          </Col>
        </Row>
        <Row container>
          <Col md={6}>
            <div css={sectionTreeImageBox(opportunitiesHomeImage)} />
          </Col>
          <Col md={6}>
            <div className="section-tree-item-text">
              <h3>{getText('opportunities')}</h3>
              <p data-strank-key="opportunitiesShortText">{getText('opportunitiesShortText')}</p>
            </div>
            {/* <div role="button" tabIndex={0} css={goToButton}>
              <p>{getText('readMore')}</p>
              <GoToArrow />
            </div> */}
          </Col>
        </Row>
      </section>
      <UpcomingEvents />
      <LatestNews titleKey="latestNews" />
    </>
  );
};

export default Home;
