import { css } from '@emotion/react';

export const listLabel = css({
  marginBottom: 16,
});

export const listContainer = (hasAddButton) =>
  css(
    {
      display: 'flex',
      flexDirection: 'column',
    },
    hasAddButton && {
      marginBottom: 16,
    },
  );

export const itemContainer = (theme) =>
  css({
    display: 'flex',
    marginBottom: 16,
    borderRadius: 6,
    padding: '24px 16px',
    border: `1px solid ${theme.whiteGrayDark}`,

    '&:last-child': {
      marginBottom: 0,
    },
  });

export const itemContent = (theme) =>
  css({
    zIndex: 100,
    minWidth: 45,
    minHeight: 50,
    backgroundColor: theme.white,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  });

export const deleteIconContainer = (theme) =>
  css({
    cursor: 'pointer',
    paddingRight: 16,
    marginRight: 16,
    display: 'flex',
    alignItems: 'center',
    borderRight: `1px solid ${theme.whiteGrayDark}`,
    backgroundColor: theme.white,
    transition: 'all .4s ease-in-out',
  });
