import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { CheckButton } from '../Button';
import { Icon } from '../Icon';
import { tableHead, tableHeadCell, tableHeadIcon } from './styles';

const TableHead = ({ columns, getData, tableOptions, hasSelection, selectRow, allSelected }) => {
  const theme = useTheme();
  const [sort, setSort] = useState(null);

  const changeSort = (column) => () => {
    const { value } = column;
    const isDesc = sort === value;
    const newSort = isDesc ? `${value}Descending` : sort === `${value}Descending` ? null : value;
    setSort(newSort);
    getData({
      sort: newSort && { sortField: value, sortOrder: isDesc ? 'desc' : 'asc' },
    });
  };

  return (
    <div css={tableHead}>
      {hasSelection && !!tableOptions.current.totalResults && (
        <div css={tableHeadCell({ width: 40, render: true }, theme)}>
          <CheckButton onChange={selectRow} checked={allSelected} />
        </div>
      )}
      {columns.map((column) => {
        const { sortable, name, value } = column;
        return sortable ? (
          <div key={name} css={tableHeadCell(column)} role="button" tabIndex="-1" onClick={changeSort(column)}>
            {name}
            <Icon iconName="arrow_downward" css={tableHeadIcon(sort === value, sort === `${value}Descending`)} />
          </div>
        ) : (
          <div key={name} css={tableHeadCell(column, theme)}>
            {name}
          </div>
        );
      })}
    </div>
  );
};

TableHead.propTypes = {
  columns: PropTypes.array,
  getData: PropTypes.func,
  hasSelection: PropTypes.bool,
  selectRow: PropTypes.func,
  allSelected: PropTypes.bool,
  tableOptions: PropTypes.object,
};

export default TableHead;
