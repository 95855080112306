import { useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { Icon } from '../../Icon';
import { languageIconContainer, contentContainer, item } from './styles';

const MobileLanguages = ({ className }) => {
  const { getText, allEnvLanguages, changeLanguage, language } = useTranslations();
  const [open, setOpen] = useState(false);

  const hideContainer = (event = {}) => {
    event.stopPropagation();
    const { currentTarget, relatedTarget } = event;
    if (currentTarget.contains(relatedTarget)) return;
    setOpen(false);
  };

  return (
    <div role="button" tabIndex={0} onClick={() => setOpen(true)} onBlur={hideContainer}>
      <div role="button" tabIndex={0} onClick={(event) => event.preventDefault()} css={languageIconContainer(open)}>
        <p>{open ? getText('language') : language?.name}</p>
        <Icon iconName={open ? 'fa fa-times' : 'fa fa-globe'} {...(open && { onClick: hideContainer })} />
      </div>

      {open && (
        <div css={contentContainer} {...(className && { className })}>
          {allEnvLanguages?.map((el, ind) => {
            return (
              <div
                role="button"
                tabIndex={0}
                key={ind}
                onClick={() => changeLanguage(el?.code)}
                css={item(el.name === language.name)}>
                <p className="name">
                  {el?.code} ({getText(`${el?.name}`)})
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MobileLanguages;
