export const languageIconContainer = (isOpen) => (theme) => ({
  cursor: isOpen ? 'default' : 'pointer',
  fontSize: '1rem',
  backgroundColor: isOpen ? theme.primary : theme.primaryDark,
  padding: '1.5em 1em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& > p': {
    margin: 0,
    fontSize: '1.3rem',
  },
});

export const contentContainer = (theme) => ({
  maxHeight: 200,
  overflow: 'auto',
  backgroundColor: theme.primaryDark,
  '::-webkit-scrollbar': {
    display: 'none',
  },
});

export const item = (selectedLanguage) => (theme) => ({
  backgroundColor: selectedLanguage && theme.darkOverlay,
  color: selectedLanguage ? theme.secondary : theme.secondaryDarker,
  padding: '1.5em 1em',
  '&:hover': {
    backgroundColor: theme.darkOverlay,
  },
  '& > .name': {
    margin: 0,
    textTransform: 'uppercase',
    fontSize: '1rem',
  },
});
