import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  useTranslations,
  getSavedLanguage,
  getBrowserLanguage,
  initTranslationsStore,
  initLanguagesStore,
} from '@veraio/strank';
import { Footer, NavBar, useDeepEffect } from 'components';
import Home from './Home';
import Products from './Products';
import News from './News';
import NewsDetails from './NewsDetails';
import Events from './Events';
import EventDetails from './EventDetails';
import Network from './Network';
import GlobalCouncil from './GlobalCouncil';
import CountryBoards from './CountryBoards';
import MemberDetails from './MemberDetails';
import CountryDetails from './CountryDetails';
import { contentContainer } from './styles';

const App = () => {
  const { changeLanguage, allEnvLanguages } = useTranslations();

  useDeepEffect(() => {
    allEnvLanguages?.length && changeLanguage(getSavedLanguage() ?? getBrowserLanguage());
  }, [allEnvLanguages]);

  useEffect(() => {
    initLanguagesStore();
    initTranslationsStore();
  }, []);

  return (
    <>
      <NavBar />
      <div css={contentContainer}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/products/:productId" component={Products} />
          <Route exact path="/news" component={News} />
          <Route exact path="/news/:newsId" component={NewsDetails} />
          <Route exact path="/events" component={Events} />
          <Route exact path="/events/:eventId" component={EventDetails} />
          <Route exact path="/network" component={Network} />
          <Route exact path="/network/global-council" component={GlobalCouncil} />
          <Route exact path="/network/member/:memberId" component={MemberDetails} />
          <Route exact path="/network/country-boards" component={CountryBoards} />
          <Route exact path="/network/country-boards/:countryId" component={CountryDetails} />
        </Switch>
      </div>
      <Footer />
    </>
  );
};

export default App;
