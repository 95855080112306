import { isNil, isString, isNumber, isArray, isPlainObject, isEmpty } from 'lodash-es';

export const noValue = (val) => isNil(val) || ((isString(val) || isArray(val) || isPlainObject(val)) && isEmpty(val));

export const isStrNum = (val) => isString(val) || isNumber(val);

export const isJsonString = (text) => {
  if (typeof text !== 'string') return false;

  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};

export const concatStrings = (...args) => args.filter(Boolean).join(' ');

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const rgbToHex = (red, green, blue) => {
  const numberToHex = (number) => {
    const hex = number.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };

  return `#${numberToHex(red)}${numberToHex(green)}${numberToHex(blue)}`;
};
