export const languageWrap = {
  position: 'relative',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const languageIconContainer = (theme) => ({
  cursor: 'pointer',
  color: theme.secondaryDarker,
  fontSize: '1rem',
});

export const contentContainer = (theme) => ({
  position: 'absolute',
  right: 0,
  top: '100%',
  width: 180,
  maxHeight: 'calc(100vh - 70px)',
  overflow: 'auto',
  backgroundColor: theme.primaryDark,
  borderBottom: `1px solid ${theme.secondaryDarker}`,

  '::-webkit-scrollbar': {
    display: 'none',
  },
});

export const item = (selectedLanguage) => (theme) => ({
  backgroundColor: selectedLanguage && theme.darkOverlay,
  color: selectedLanguage ? theme.secondary : theme.secondaryDarker,
  padding: '1em',

  '&:hover': {
    backgroundColor: theme.darkOverlay,
  },

  '& > .name': {
    margin: 0,
    textTransform: 'uppercase',
    fontSize: '0.9rem',
  },
});
