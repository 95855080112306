export const tableContainer = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.white,
});

export const tableBodyContainer = {
  marginBottom: 15,
};

export const filterBarContainer = {
  position: 'relative',
};

export const tableHead = (theme) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  cursor: 'pointer',
  borderBottom: `1px solid ${theme.border}`,
});

export const tableRow = (hasOnClick) => (theme) =>
  [
    {
      display: 'flex',
      alignItems: 'stretch',
      color: theme.grayDark,
      transition: 'background-color .3s ease-in-out',
      borderBottom: `1px solid ${theme.border}`,

      '&:hover': {
        backgroundColor: theme.whiteGray,
      },
    },
    hasOnClick && {
      cursor: 'pointer',
    },
  ];

export const tableCell = ({ width, flex, render, sm, md, lg, xl }) => [
  {
    fontSize: '1rem',
    lineHeight: 1.46,
    flex: flex ?? (width ? `0 1 ${width}px` : '1 0 60px'),
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: render ? '0 6px' : '16px 6px',
    outline: 'none',
    wordBreak: 'break-word',
  },
  sm && {
    '@media (max-width: 575px)': {
      display: 'none',
    },
  },
  md && {
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  lg && {
    '@media (max-width: 991px)': {
      display: 'none',
    },
  },
  xl && {
    '@media (max-width: 1199px)': {
      display: 'none',
    },
  },
];

export const tableHeadCell = (options) => [
  ...tableCell(options),
  {
    fontSize: '1rem',
    padding: 6,
  },
  options?.sortable && {
    cursor: 'default',
    '& b': {
      cursor: 'pointer',
      '&:hover + i': {
        opacity: 1,
      },
    },
  },
];

export const tableHeadIcon = (isAsc, isDesc) => ({
  fontSize: 18,
  opacity: isAsc || isDesc ? 1 : 0,
  transform: `rotate(${isDesc ? 0 : 180}deg)`,
  transition: 'transform 300ms',
});

export const tableCellContent = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '& span': {
    display: 'inline',
  },
};

export const tableCellEmpty = (theme) => ({
  width: 32,
  height: 4,
  backgroundColor: theme.whiteGray,
  borderRadius: 12,
});

export const paginationContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexWrap: 'nowrap',
};

export const shownResultsIndicator = (theme) => ({
  color: theme.textLightSecondary,
  width: 80,
  textAlign: 'center',
  fontSize: 11,
  marginRight: 8,
});

export const paginationButton = (disabled, isActive, small) => (theme) =>
  [
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      userSelect: 'none',
      width: small ? 28 : 36,
      height: small ? 28 : 36,
      margin: '0 6px',
      border: `1px solid ${disabled ? theme.textLightDisabled : theme.primary}`,
      borderRadius: '50%',
      backgroundColor: theme.white,
      color: theme.primary,
      transition: 'all 0.4s ease',

      '&:hover': {
        backgroundColor: theme.primary,
        color: theme.textDarkPrimary,
      },
    },
    isActive && {
      backgroundColor: theme.primary,
      color: theme.textDarkPrimary,
    },
    disabled && {
      cursor: 'not-allowed',
      color: theme.textLightDisabled,

      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.textLightDisabled,
      },
    },
  ];

export const tableMessageContainer = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8rem 3rem',
  flexDirection: 'column',

  '& > .table-message-icon': {
    marginBottom: '2.2rem',
  },

  '& > .table-message-title': {
    fontWeight: 500,
    marginBottom: 12,
  },

  '& > .table-message-subtitle': {
    color: theme.textLightSecondary,
  },
});

export const pageSizeDropdown = {
  width: 120,
};
