import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { isFunction } from 'lodash-es';
import { radioButtonContainer } from './styles';

const CheckButton = ({ label, value, onChange, size = 24, className }) => {
  const theme = useTheme();
  const [isChecked, setIsChecked] = useState(!!value);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  const handleChange = () => {
    setIsChecked((prev) => {
      isFunction(onChange) && onChange(!prev);
      return !prev;
    });
  };

  return (
    <div
      role="checkbox"
      tabIndex={0}
      css={radioButtonContainer(isChecked, size, !!label, theme)}
      className={className}
      onClick={handleChange}
      aria-checked={isChecked}>
      <svg className="input-indicator" viewBox="0 0 40 40">
        <circle id="border" r="20" cx="50%" cy="50%" />
        <circle id="dot" r="10" cx="50%" cy="50%" />
      </svg>
      <span className="label">{label}</span>
    </div>
  );
};

CheckButton.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.number,
  label: PropTypes.any,
};

export default CheckButton;
