import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { isFunction } from 'lodash-es';
import { Icon } from '../Icon';
import { ReactComponent as GoToArrow } from 'assets/icons/go-to-icon.svg';
import { buttonContainer } from './styles';

const Button = ({
  children,
  type = 'primary',
  outline,
  clear,
  onClick,
  disabled,
  arrow,
  leftIcon,
  small,
  large,
  className,
  preventDefault,
  linkTo,
  color,
  hoveColor,
  backColor,
  hoveBgColor,
  noHover = false,
  loading: loadingProp = false,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(loadingProp);
  const buttonStyles = buttonContainer(
    {
      type,
      theme,
      disabled,
      color,
      hoveColor,
      backColor,
      hoveBgColor,
      small,
      large,
      outline,
      clear,
      noHover,
    },
    loading,
  );

  useEffect(() => {
    loadingProp !== loading && setLoading(loadingProp);
  }, [loadingProp]);

  const handleClick = async (e) => {
    if (disabled) return e.preventDefault();
    preventDefault && e.preventDefault();
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  return linkTo ? (
    <Link to={linkTo} className={className} css={buttonStyles}>
      {leftIcon && <Icon {...leftIcon} />}
      {children}
      {arrow && <GoToArrow className="arrow-style" />}
    </Link>
  ) : (
    <div
      role="button"
      tabIndex={0}
      {...(isFunction(onClick) && { onClick: handleClick })}
      css={buttonStyles}
      className={className}>
      {leftIcon && <Icon {...leftIcon} />}
      {children}
      {arrow && <GoToArrow className="arrow-style" />}
    </div>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf(['primary', 'secondary', 'gray', 'success', 'warning', 'info']),
  outline: PropTypes.bool,
  clear: PropTypes.bool,
  disabled: PropTypes.bool,
  arrow: PropTypes.bool,
  onClick: PropTypes.func,
  preventDefault: PropTypes.bool,
  color: PropTypes.string,
  hoveColor: PropTypes.string,
  backColor: PropTypes.string,
  hoveBgColor: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  className: PropTypes.string,
  leftIcon: PropTypes.object,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  noHover: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Button;
