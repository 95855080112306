export const wrap = (theme) => ({
  border: `1px solid ${theme.primary}`,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .news-image-wrap': {
    padding: '1em',
    borderBottom: `1px solid ${theme.primary}`,
  },
  '& .news-text-wrap': {
    height: '100%',
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .news-date': {
      fontSize: '0.8em',
      marginBottom: '0.8em',
    },
    '& .news-title': {
      marginBottom: '0.8em',
    },
    '& .news-text': {
      fontSize: '0.9em',
    },
  },
});

export const newsImage = (url) => (theme) => ({
  backgroundColor: theme.primaryDark,
  backgroundImage: `url(${url})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  aspectRatio: ' 16 / 9',
  width: '100%',
});
