import { useState, useEffect } from 'react';
import { useTranslations } from '@veraio/strank';
import { Link, useParams } from 'react-router-dom';
import { isNil, isEmpty } from 'lodash-es';
import { Row, Col, Icon, Button, showError } from 'components';
import { getCountryManagers, getCountryDetails } from 'services';
import { getImagePath, getTranslations, getTranslationData } from 'utils';
import { ReactComponent as EnvelopeIcon } from 'assets/icons/envelope-icon.svg';
import { header, body, managerBox, footer } from './styles';

const CountryDetails = () => {
  const { getText, language } = useTranslations();
  const params = useParams();
  const countryId = params?.countryId;
  const [managers, setManagers] = useState(null);
  const [country, setCountry] = useState(null);

  useEffect(() => {
    fetchCountry();
    language && fetchManagers(1);
  }, [countryId, language?.code]);

  const fetchManagers = async (page) => {
    const [res, err] = await getCountryManagers({
      page,
      sort: 'name',
      filter: `(countryId='${countryId}')`,
      fields: `id,collectionId,name,description,email,image,${getTranslations(['description'], language?.code)}`,
    });
    if (err) return showError(err?.data);

    setManagers((prev) =>
      isNil(prev) || page === 1
        ? res
        : {
            ...res,
            items: [...prev.items, ...res.items],
          },
    );
  };

  const fetchCountry = async () => {
    const [res, err] = await getCountryDetails(countryId);
    if (err) return showError(err?.data);
    setCountry(res);
  };

  const handleMore = () => {
    const nextPage = managers?.page + 1;
    fetchManagers(nextPage);
  };

  return (
    <>
      <div css={header}>
        <Row container>
          <Col>
            <Link to={'/network/country-boards'}>
              <div className="go-back-to-all">
                <Icon iconName="la la-arrow-left" />
                <p>{getText('allCountryBoards')}</p>
              </div>
            </Link>
          </Col>
        </Row>
      </div>
      <div css={body}>
        <Row container>
          <Col md={5} lg={3}>
            <div className="country-box">
              <img src={getImagePath(country)} alt={`${country?.name}-img`} />
              <h5>{country?.name}</h5>
            </div>
          </Col>
          <Col md={7} lg={9}>
            <Row>
              {managers?.items?.map((el) => (
                <Col key={el?.id} lg={6} xl={4} className="col-item">
                  <div css={managerBox(getImagePath(el))}>
                    <div className="box-header">
                      {/* <div className="avatar-image" /> */}
                      <h6>{el?.name}</h6>
                      <p>{getTranslationData(el, 'description', language?.code)}</p>
                    </div>
                    {!isEmpty(el?.email) && (
                      <a className="manager-contact-button" href={`mailto:${el?.email}`}>
                        <EnvelopeIcon />
                        <p>{`${el?.email}`}</p>
                      </a>
                    )}
                  </div>
                </Col>
              ))}
            </Row>
            <div css={footer}>
              {managers?.page < managers?.totalPages && (
                <Button onClick={handleMore} type="gray">
                  {getText('moreNews')}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CountryDetails;
