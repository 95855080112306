import globalCouncilImage from 'assets/network/global-council.jpg';
import countryBoardsImage from 'assets/network/country-boards.jpg';

export const networkTypes = [
  {
    id: 1,
    nameKey: 'globalCouncil',
    textKey: 'globalCouncilText',
    image: globalCouncilImage,
    buttonTextKey: 'aboutGlobalCouncil',
    linkTo: 'global-council',
  },
  {
    id: 2,
    nameKey: 'countryBoards',
    textKey: 'countryBoardsText',
    image: countryBoardsImage,
    buttonTextKey: 'aboutCountryBoards',
    linkTo: 'country-boards',
  },
];
