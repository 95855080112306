import { cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import { inRange } from 'lodash-es';
import { COLUMN_DISPLAY_NAME } from './Col';
import { containerStyle, rowComp } from './styles';

const NUMBER_OF_COLUMNS = 12;

export const ROW_DISPLAY_NAME = 'GridRow';

const Row = (props) => {
  const { toArray } = Children;
  const { children: initChilds, container, xs, sm, md, lg, xl, xxl, xxxl } = props;
  const { gap, verticalGap, horizontalGap, className } = props;
  const children = toArray(initChilds).filter(Boolean);

  const getValidSize = (size) => (inRange(size, 1, NUMBER_OF_COLUMNS + 1) || size === 'auto' ? size : null);

  const getColumnSize = (el, type) => getValidSize(el.props[type] || 0);

  const columnChildren = children.map((el, i) =>
    el?.type?.displayName === COLUMN_DISPLAY_NAME
      ? cloneElement(el, {
          key: `Column${i}`,
          gap: gap ?? el.props.gap,
          verticalGap: verticalGap ?? el.props.verticalGap,
          horizontalGap: horizontalGap ?? el.props.horizontalGap,
          xs: getColumnSize(el, 'xs') || getValidSize(xs),
          sm: getColumnSize(el, 'sm') || getValidSize(sm),
          md: getColumnSize(el, 'md') || getValidSize(md),
          lg: getColumnSize(el, 'lg') || getValidSize(lg),
          xl: getColumnSize(el, 'xl') || getValidSize(xl),
          xxl: getColumnSize(el, 'xxl') || getValidSize(xxl),
          xxxl: getColumnSize(el, 'xxxl') || getValidSize(xxxl),
        })
      : el,
  );

  const rowComponent = (
    <div {...(className && { className })} css={rowComp(props)}>
      {columnChildren}
    </div>
  );

  return container ? <div css={containerStyle}>{rowComponent}</div> : rowComponent;
};

Row.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  container: PropTypes.bool,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  xxl: PropTypes.number,
  xxxl: PropTypes.number,
  gap: PropTypes.number,
  verticalGap: PropTypes.number,
  horizontalGap: PropTypes.number,
  className: PropTypes.string,
};

Row.displayName = ROW_DISPLAY_NAME;

export default Row;
