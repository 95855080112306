import { css, keyframes } from '@emotion/react';

const loading = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

export const skeletonClass = ({ count, duration, height, width, circle, ...restStyles }) =>
  css(
    {
      display: 'block',
      position: 'relative',
      borderRadius: 4,
      lineHeight: 1,
      width: width || '100%',
      margin: count > 1 ? '1em 0' : 0,
      background: '#4C4A48',
      overflow: 'hidden',
      '&:after': {
        content: "''",
        display: 'block',
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        transform: 'translateX(-100px)',
        background: 'linear-gradient(90deg, transparent, rgba(247,237,227,0.3), transparent)',
        animation: `${loading} ${duration}s infinite`,
      },
    },
    height && {
      height,
    },
    width &&
      height &&
      circle && {
        borderRadius: '50%',
      },
    { ...restStyles },
  );
