import { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import Icon from './Icon';

const LoadFonts = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { fontAwesome, lineAwesome, iconsMind } = useTheme();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    !isLoaded && (
      <div style={{ visibility: 'hidden' }}>
        {fontAwesome && (
          <>
            <Icon iconName="fab fa-btc" />
            <Icon iconName="far fa-check-circle" />
            <Icon iconName="fas fa-check-circle" />
          </>
        )}
        {lineAwesome && <Icon iconName="la la-close" />}
        {iconsMind && <Icon iconName="openmind i-Add-Window" />}
      </div>
    )
  );
};

export default LoadFonts;
