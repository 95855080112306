export const header = (theme) => ({
  padding: '0 4em',
  marginBottom: '4em',
  '&  h2': {
    textTransform: 'uppercase',
    textAlign: 'center',
    margin: '1em 0 0.5em 0',
  },
  '&  p': {
    color: theme.secondaryDarker,
  },
  '@media (max-width: 991px)': {
    padding: '0 2em',
    marginBottom: '3em',
  },
});

export const body = {
  padding: '0 4em',
  marginBottom: '2em',
  '@media (max-width: 991px)': {
    padding: '0 2em',
    '&  > div ': {
      maxWidth: '100%',
    },
  },
};

export const networkBox = (image) => (theme) => ({
  backgroundColor: theme.primaryDark,
  marginBottom: '2em',
  padding: '1em',
  '& .network-bg-image': {
    backgroundColor: theme.primaryDark,
    backgroundImage: `url(${image})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    aspectRatio: '3 / 1',
    width: '100%',
    marginBottom: '2em',
  },
  '& .network-img': {
    width: '100%',
    height: 'auto',
    marginBottom: '2em',
  },
  '& > h3': {
    textTransform: 'uppercase',
    marginBottom: '0.5em',
  },
  '& > p': {
    color: theme.secondaryDarker,
    marginBottom: '1.5em',
  },
  '@media (max-width: 767px)': {
    '& .network-image': {
      aspectRatio: '16 / 9',
    },
  },
});
