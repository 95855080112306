import { css } from '@emotion/react';
import { isNumber, isString } from 'lodash-es';

const NUMBER_OF_COLUMNS = 12;

export const containerStyle = {
  margin: '0px auto',
  overflow: 'hidden',
  '@media (max-width: 576px)': {
    width: '100%',
  },
  '@media (min-width: 576px)': {
    maxWidth: 540,
  },
  '@media (min-width: 768px)': {
    maxWidth: 720,
  },
  '@media (min-width: 992px)': {
    maxWidth: 960,
  },
  '@media (min-width: 1200px)': {
    maxWidth: 1140,
  },
  '@media (min-width: 1400px)': {
    maxWidth: 1320,
  },
};

export const row = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
};

export const rowComp = ({
  column,
  reverse,
  noWrap,
  align,
  justify,
  padding,
  margin,
  minWidth,
  className,
  gap,
  horizontalGap,
  verticalGap,
  flex,
}) =>
  css(
    row,
    {
      flexDirection: column ? (reverse ? 'column-reverse' : 'column') : reverse ? 'row-reverse' : 'row',
      flexWrap: noWrap ? 'no-wrap' : 'wrap',
    },
    align && {
      alignItems: align,
    },
    justify && {
      justifyContent: justify,
    },
    gap && {
      margin: `-${gap}px`,
    },
    horizontalGap && {
      marginLeft: `-${horizontalGap}px`,
      marginRight: `-${horizontalGap}px`,
    },
    verticalGap && {
      marginTop: `-${verticalGap}px`,
      marginBottom: `-${verticalGap}px`,
    },
    flex && { flex },
    padding && { padding },
    margin && { margin },
    minWidth && { minWidth },
    className || {},
  );

const baseCol = {
  flexBasis: '100%',
  maxWidth: '100%',
  width: '100%',
  flexShrink: 0,
  outline: 'none',
};

const breakPointStyles = (type, px, isMax) => ({
  [`@media (${isMax ? 'max' : 'min'}-width: ${px}px)`]:
    type === 'auto'
      ? {
          flex: '0 0 auto',
          width: 'auto',
        }
      : isNumber(type)
      ? {
          flexBasis: `${100 / (NUMBER_OF_COLUMNS / type)}%`,
          maxWidth: `${100 / (NUMBER_OF_COLUMNS / type)}%`,
        }
      : { flex: '1 0 0%' },
});

export const colComp = ({
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  xxxl,
  className = {},
  gap,
  verticalGap,
  horizontalGap,
  dFlex,
  align,
  justify,
  direction,
  wrap,
  flow,
  flex,
  basis,
  grow,
  shrink,
  height,
  width,
}) =>
  css(
    baseCol,
    xs && breakPointStyles(xs, 575, true),
    sm && breakPointStyles(sm, 576),
    md && breakPointStyles(md, 768),
    lg && breakPointStyles(lg, 992),
    xl && breakPointStyles(xl, 1200),
    xxl && breakPointStyles(xxl, 1400),
    xxxl && breakPointStyles(xxxl, 1600),
    (isNumber(gap) || isString(gap)) && {
      padding: gap,
    },
    (isNumber(verticalGap) || isString(verticalGap)) && {
      paddingTop: verticalGap,
      paddingBottom: verticalGap,
    },
    (isNumber(horizontalGap) || isString(horizontalGap)) && {
      paddingLeft: horizontalGap,
      paddingRight: horizontalGap,
    },
    dFlex && {
      display: 'flex',
    },
    align && {
      alignItems: align,
    },
    justify && {
      justifyContent: justify,
    },
    (isNumber(basis) || isString(basis)) && {
      flexBasis: basis,
    },
    (isNumber(grow) || isString(grow)) && {
      flexGrow: grow,
    },
    (isNumber(shrink) || isString(shrink)) && {
      flexShrink: shrink,
    },
    isString(flex) && {
      flex,
    },
    isString(direction) && {
      flexDirection: direction,
    },
    isString(wrap) && {
      flexWrap: wrap,
    },
    isString(flow) && {
      flexFlow: flow,
    },
    height,
    width,
    className,
  );
