import { css, keyframes } from '@emotion/react';

export const rippleBaseStyles = (theme, color, hasFocus, className) =>
  css(
    {
      position: 'relative',
      overflow: 'hidden',
      outline: 'none',
      userSelect: 'none',
      '&:before': {
        content: hasFocus ? "''" : 'none',
        display: 'block',
        opacity: '0',
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        zIndex: '10',
        transition: 'all 300ms',
      },
      '&:after': {
        content: "''",
        display: 'block',
        opacity: '0',
        position: 'absolute',
        borderRadius: '50%',
        backgroundColor: color ? theme[color] : theme.primaryDark,
        pointerEvents: 'none',
        transform: 'scale(0)',
        transformOrigin: 'center center',
        zIndex: '20',
      },
    },
    className,
  );

export const onFocus = (theme, color) =>
  css({
    '&:before': {
      backgroundColor: color ? theme[color] : theme.primaryDark,
      opacity: '1',
    },
  });

const radiusIn = keyframes`
  from {
    animation-timing-function: linear;
    transform: scale(0);
  }

  to {
    transform: scale(2.5);
  }
`;

const opacityIn = keyframes`
  from {
    animation-timing-function: linear;
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const animationOn = (left, top, size) => css`
  &:after {
    width: ${size}px;
    height: ${size}px;
    left: ${left}px;
    top: ${top}px;
    animation: ${radiusIn} 300ms forwards, ${opacityIn} 100ms forwards;
  }
`;
