import { useState, useContext, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { Route, matchPath } from 'react-router-dom';
import { omit } from 'lodash-es';
import { renewSession, getToken } from '@oneecosystem/authenticate';
import { getMyProfile } from '../../services/profiles';

const AuthContext = createContext({});
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = (props) => {
  const { children, renderRedirect, renderLogout } = props;
  const [user, setUser] = useState({ isAuthenticated: !!getToken() });

  useEffect(() => {
    user?.isAuthenticated
      ? prepareUser()
      : !matchPath(window.location.pathname, '/authorization-callback') &&
        setUser({ loaded: true, isAuthenticated: false });
  }, []);

  useEffect(() => {
    const loader = document.getElementById('splash-screen');
    if (user?.loaded && loader) loader.style.display = 'none';
  }, [user?.loaded]);

  const prepareUser = async () => {
    try {
      await renewSession();
      const [userData] = await getMyProfile();

      setUser({
        ...userData,
        isAuthenticated: true,
        loaded: true,
      });
    } catch (err) {
      setUser({ isAuthenticated: false, loaded: true });
    }
  };

  const userData = omit(user, ['loaded', 'isAuthenticated']);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: user.isAuthenticated,
        user: userData,
        setUser,
        prepareUser,
      }}>
      {renderRedirect && <Route exact path="/authorization-callback" component={renderRedirect} />}
      {renderLogout && <Route exact path="/logout" component={renderLogout} />}
      {user.loaded && children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
  renderRedirect: PropTypes.func,
  renderLogout: PropTypes.func,
};
