import PropTypes from 'prop-types';
import { isObjectLike, isFunction } from 'lodash-es';
import { Icon } from '../Icon';
import Input from '../Input';
import useSearchBar from './useSearchBar';
import {
  searchBarContainer,
  searchBarInputContainer,
  searchBarInput,
  searchBarInputIcon,
  searchBarSuggestionsContainer,
  searchBarSuggestionItem,
} from './styles';

const SearchBar = (props) => {
  const {
    inputRef,
    placeholder,
    compareBy = 'value',
    className,
    renderInput,
    renderSuggestionsContainer,
    renderSuggestion,
  } = props;

  const { input, onSelect, isOpen, suggestions } = useSearchBar(props);

  const renderListOfSuggestions = suggestions.map((el, i) =>
    isFunction(renderSuggestion) ? (
      renderSuggestion({
        css: searchBarSuggestionItem,
        onSelect,
        item: el,
      })
    ) : (
      <div
        key={`suggestion-${i}`}
        role="button"
        tabIndex={0}
        css={searchBarSuggestionItem}
        onClick={() => onSelect(el)}>
        {isObjectLike(el) ? el[compareBy] : el}
      </div>
    ),
  );

  return (
    <div css={searchBarContainer} className={className}>
      {isFunction(renderInput) ? (
        renderInput({
          containerProps: {
            css: searchBarInputContainer,
          },
          inputProps: {
            ref: inputRef,
            placeholder,
            css: searchBarInput,
            ...input,
          },
          iconProps: {
            css: searchBarInputIcon,
          },
        })
      ) : (
        <div css={searchBarInputContainer}>
          <Input
            ref={inputRef}
            placeholder={placeholder}
            css={searchBarInput}
            maxLength="2048"
            type="text"
            spellCheck="false"
            aria-autocomplete="both"
            aria-haspopup="false"
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            {...input}
          />
          <label htmlFor={input.id}>
            <Icon iconName="imind-magnifi-glass2" css={searchBarInputIcon} />
          </label>
        </div>
      )}

      {isFunction(renderSuggestionsContainer)
        ? renderSuggestionsContainer({
            isOpen,
            suggestions,
            css: searchBarSuggestionsContainer,
            children: renderListOfSuggestions,
          })
        : isOpen && !!suggestions.length && <div css={searchBarSuggestionsContainer}>{renderListOfSuggestions}</div>}
    </div>
  );
};

SearchBar.propTypes = {
  inputRef: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  compareBy: PropTypes.string,
  renderInput: PropTypes.func,
  renderSuggestionsContainer: PropTypes.func,
  renderSuggestion: PropTypes.func,
};

export default SearchBar;
