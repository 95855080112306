/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'components';
import { useTranslations } from '@veraio/strank';
import { wrap } from './styles';

const ProductsTabsContent = ({ title, text, image, upcoming }) => {
  const { getText } = useTranslations();

  return (
    <div css={wrap}>
      <Row justify="space-between" align="center">
        <Col lg={6}>
          {upcoming && <h5 className="content-subtitle">{getText('upcomingProject')}</h5>}
          <h4 className="content-title">{title}</h4>
          <p className="content-text" data-strank-key={text}>
            {getText(text)}
          </p>
        </Col>
        <Col lg={4}>
          <div className="product-image-wrap">
            <img src={image} alt={`${title}-logo`} className="product-image" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

ProductsTabsContent.propTypes = {
  image: PropTypes.any,
  text: PropTypes.string,
  title: PropTypes.string,
  upcoming: PropTypes.bool,
};

export default ProductsTabsContent;
