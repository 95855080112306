import { useState, useRef, useCallback, useEffect, useImperativeHandle } from 'react';
import { isArray, isFunction, isObjectLike, uniqueId, debounce } from 'lodash-es';

export default ({ value, options, getOptions, onInputChange, onBlur, onChange, compareBy = 'value' }, ref) => {
  const hasPredefinedSuggestions = isArray(options);
  const hasDynamicSuggestions = isFunction(getOptions);

  const [inputValue, setInputValue] = useState(value ?? '');
  const [suggestions, setSuggestions] = useState(options ?? []);
  const [isOpen, setIsOpen] = useState(false);
  const lastSearch = useRef(null);
  const performAsyncSearch = useCallback(
    debounce(async (newValue) => {
      const result = await getOptions(newValue);
      if (result?.length) {
        setSuggestions(result);
        setIsOpen(true);
      }
    }, 700),
    [getOptions],
  );

  useImperativeHandle(ref, () => ({
    isOpen,
    suggestions,
    inputValue,
    changeIsOpen: setIsOpen,
    changeSuggestions: setSuggestions,
    changeInputValue: setInputValue,
    triggerNewSearch: (newVal) => performSearch(newVal),
    blur: () => handleBlur(),
  }));

  useEffect(() => {
    performSearch(inputValue);
  }, [options]);

  const handleChange = ({ target: { value: input } }) => {
    setInputValue(input);
    isFunction(onInputChange) && onInputChange(input.trim());
    performSearch(input.trim());
  };

  const handleBlur = (event) => {
    isFunction(onBlur) && onBlur(event);
    const { relatedTarget, target } = event ?? {};

    if (!target?.parentNode?.parentNode?.contains(relatedTarget)) setIsOpen(false);
  };

  const handleSelect = (item) => {
    setIsOpen(false);
    setInputValue(isObjectLike(item) ? item[compareBy] : item);
    isFunction(onChange) && onChange(item);
  };

  const handleKeyDown = () => {};

  const performSearch = (newValue) => {
    if (hasDynamicSuggestions) {
      if (lastSearch.current === newValue) return;
      if (newValue?.length < 3) return setSuggestions([]);
      lastSearch.current = newValue;

      performAsyncSearch(newValue);
    }

    if (hasPredefinedSuggestions) {
      const suggestionsList = options.filter((el) =>
        String(isObjectLike(el) ? el[compareBy] : el)
          .toLowerCase()
          .includes(newValue.toLowerCase()),
      );

      return setSuggestions(suggestionsList);
    }
  };

  return {
    input: {
      id: uniqueId(),
      value: inputValue,
      onFocus: () => setIsOpen(true),
      onChange: handleChange,
      onKeyDown: handleKeyDown,
      onBlur: handleBlur,
    },
    onSelect: handleSelect,
    isOpen,
    suggestions,
  };
};
