import { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { containerStyle } from './styles';

const BodyOverflow = (props) => {
  const { children, disableScroll } = props;
  const containerId = useRef(parseInt(Math.random() * 100000, 10));
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const container = document.createElement('div');
    container.id = containerId.current;
    container.classList.add(containerStyle(props)?.name);
    document.body.insertBefore(container, document.body.getElementsByTagName('script')[0]);
    setReady(true);
    disableScroll && (document.body.style.overflow = 'hidden');

    return () => {
      document.body.removeChild(container);
      disableScroll && (document.body.style.overflow = 'auto');
    };
  }, []);

  return ready && ReactDOM.createPortal(children, document.getElementById(containerId.current));
};

BodyOverflow.propTypes = {
  children: PropTypes.any,
  zIndex: PropTypes.number,
  className: PropTypes.string,
  fullScreen: PropTypes.bool,
  fixed: PropTypes.bool,
  disableScroll: PropTypes.bool,
};

export default BodyOverflow;
