export const baseIcon = (
  { color, onClick, size, filter, margin, padding, bold },
  theme
) => [
  {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontVariant: 'normal',
    lineHeight: '1',
    textDecoration: 'inherit',
    textRendering: 'optimizeLegibility',
    textTransform: 'none',
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased',
    fontSmoothing: 'antialiased',
    fontSize: size ?? 24,
    fontFeatureSettings: "'liga'",
    userSelect: 'none',
  },
  color && {
    color: theme[color] ?? color,
  },
  margin && {
    margin: `${margin} !important`,
  },
  padding && {
    padding: `${padding} !important`,
  },
  onClick && {
    cursor: 'pointer',
  },
  filter && {
    filter,
  },
  bold && {
    fontWeight: '900 !important',
  },
];
