export const searchBarContainer = {
  position: 'relative',
  zIndex: 50,
  width: '100%',
  borderRadius: 24,
};

export const searchBarInputContainer = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 24,
  backgroundColor: theme.borderVeryLight,
  border: `1px solid ${theme.border}`,
});

export const searchBarInput = {
  fontSize: '1.1rem',
  lineHeight: 1.2,
  paddingRight: '1rem',
  outline: 'none',
  padding: '0.8rem 1rem',
  border: 'none',
  background: 'transparent',
  borderRadius: 24,

  '&:focus, &:active': {
    border: 'none',
    boxShadow: 'none',
  },
};

export const searchBarInputIcon = {
  cursor: 'text',
  fontSize: 17,
  padding: '0.8rem 1rem 0.8rem 0',
};

export const searchBarSuggestionsContainer = (theme) => ({
  position: 'absolute',
  top: 'calc(100% + 8px)',
  left: 0,
  right: 0,
  color: theme.textLightSecondary,
  maxHeight: 300,
  zIndex: -20,
  overflowY: 'auto',
  backgroundColor: theme.white,
  boxShadow: theme.shadow,
});

export const searchBarSuggestionItem = (theme) => ({
  padding: '0.8rem 1.5rem',

  '&:focus, &:hover': {
    outline: 'none',
    backgroundColor: theme.primary,
    color: theme.textDarkPrimary,
  },
});
