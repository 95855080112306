export const linkButton = (size) => (theme) => ({
  display: 'inline-flex',
  alignItems: 'center',
  color: theme.secondaryDarker,
  '&  > p ': {
    margin: 0,
    textTransform: 'uppercase',
    marginRight: '0.5em',
    whiteSpace: 'nowrap',
    fontSize: size?.fontSize ?? '1rem',
  },
  '&  > svg ': {
    width: size?.iconSize ?? '0.7rem',
  },

  '&:hover': {
    color: theme.secondary,
  },
});
