import { useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as IconOpen } from 'assets/icons/chevron-down-open.svg';
import { ReactComponent as IconClose } from 'assets/icons/chevron-down-close.svg';
import { navLinkStyle, navIcon } from './styles';

const MobileNavDropdown = ({ name, content }) => {
  const location = useLocation();
  const { getText } = useTranslations();
  const [open, setOpen] = useState(false);
  const NavIcon = open ? IconOpen : IconClose;
  const isActive = location?.pathname?.includes(name);

  const hideContainer = (event = {}) => {
    event.stopPropagation();
    const { currentTarget, relatedTarget } = event;
    if (currentTarget.contains(relatedTarget)) return;
    setOpen(false);
  };

  return (
    <div role="button" tabIndex={0} onClick={() => setOpen(true)} onBlur={hideContainer}>
      <div role="button" tabIndex={0} onClick={(event) => event.preventDefault()} css={navLinkStyle(isActive)}>
        {getText(name)}
        <NavIcon css={navIcon} {...(open && { onClick: hideContainer })} />
      </div>
      {open && <div>{content}</div>}
    </div>
  );
};

MobileNavDropdown.propTypes = {
  name: PropTypes.string,
  content: PropTypes.node,
};

export default MobileNavDropdown;
