import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { isFunction, uniqueId, isArray, uniqBy } from 'lodash-es';
import hash from 'object-hash';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { listLabel, listContainer, itemContainer, itemContent, deleteIconContainer } from './styles';

const List = forwardRef(
  ({ label, items: itemsProp, onDelete, uniqueBy, renderAddNew, renderElement, addNewLabel }, reference) => {
    const theme = useTheme();
    const [items, setItems] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const selectedItemRef = useRef();
    const hasAddNew = isFunction(renderAddNew);
    const addUuid = (el) => ({
      value: el,
      uuid: uniqueBy ? el[uniqueBy] : uniqueId(),
    });

    useImperativeHandle(reference, () => ({
      getItems: () => items.map((el) => el.value),
      addItem: (newItem) => handleCreateNew(newItem),
      deleteItem: (uuid) => (isArray(uuid) ? uuid.forEach(handleSoftDelete) : handleSoftDelete(uuid)),
      deleteAll: () => setItems([]),
    }));

    useEffect(() => {
      setItems(isArray(itemsProp) ? itemsProp.map(addUuid) : []);
    }, [hash({ itemsProp })]);

    const handleDelete = (uuid) => {
      isFunction(onDelete) && onDelete(items.find((el) => el.uuid === uuid)?.value, handleCreateNew);
      handleSoftDelete(uuid);
    };

    const handleSoftDelete = (uuid) => setItems((prev) => prev.filter((el) => el.uuid !== uuid));

    const handleCreateNew = (newElement) => {
      setIsEdit(false);
      const newItem = isArray(newElement) ? newElement.map(addUuid) : [addUuid(newElement)];

      setItems((prev) => uniqBy([...prev, ...newItem], 'uuid'));
      return newItem;
    };

    const handleEdit = (uuid, value) => {
      setIsEdit(false);
      setItems((prev) => prev.map((el) => (el.uuid !== uuid ? el : { ...el, value })));
    };

    return (
      <>
        {label && <h3 css={listLabel}>{label}</h3>}
        {!!items?.length && (
          <div css={listContainer(hasAddNew)}>
            {items.map((el) => (
              <div key={el.uuid} css={itemContainer(theme)}>
                <div
                  tabIndex={-1}
                  role="button"
                  className="deleteIcon"
                  css={deleteIconContainer(theme)}
                  onClick={() => handleDelete(el.uuid)}>
                  <Icon iconName="far fa-trash-alt" color="error" size={22} />
                </div>
                <div css={itemContent(theme)}>{isFunction(renderElement) ? renderElement(el.value) : el.value}</div>
              </div>
            ))}
          </div>
        )}
        {hasAddNew &&
          (!isEdit ? (
            <Button
              leftIcon={{ iconName: 'add', material: true, size: 15 }}
              small
              clear
              onClick={() => setIsEdit(true)}>
              {addNewLabel ?? 'Add New Item'}
            </Button>
          ) : (
            renderAddNew({
              onCreate: handleCreateNew,
              onDelete: handleDelete,
              onEdit: handleEdit,
              onCancel: () => setIsEdit(false),
              item: selectedItemRef.current,
            })
          ))}
      </>
    );
  },
);

List.propTypes = {
  label: PropTypes.string,
  items: PropTypes.array,
  renderElement: PropTypes.func,
  renderAddNew: PropTypes.func,
  addNewLabel: PropTypes.string,
  onDelete: PropTypes.func,
  uniqueBy: PropTypes.string,
};

export default List;
