import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import { containerClass, linkItem, separator, text } from './styles';

const Breadcrumbs = (props) => {
  const { items, className } = props;

  if (!items.every((el) => el.label)) return null;

  return (
    <ul css={containerClass} {...(className && { className })}>
      {items.map((el, index, arr) => {
        const isNotLastElement = index !== arr.length - 1;
        return (
          <li key={index}>
            {el.url && isNotLastElement ? (
              <Link to={el.url} css={linkItem(isNotLastElement, el)}>
                {el.label}
              </Link>
            ) : (
              <span css={text(el)}>{el.label}</span>
            )}
            {isNotLastElement ? <Icon css={separator} iconName="las la-angle-right" /> : ''}
          </li>
        );
      })}
    </ul>
  );
};
Breadcrumbs.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
};

export default Breadcrumbs;
