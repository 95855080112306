import { css, keyframes } from '@emotion/react';

const contentAnimation = keyframes`
0% {
  transform: translate(0, -25%);
  opacity: 0;
}
100% {
  transform: translate(0, 0);
  opacity: 1;
}
`;

export const container = (className) =>
  css(
    {
      position: 'fixed',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    className,
  );

export const content = ({ large, small }) =>
  css(
    {
      position: 'relative',
      height: 'calc(100% - 56px)',
      maxWidth: 500,
      margin: '28px auto',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden auto',
      animation: `${contentAnimation} .3s ease-out`,
    },
    large && {
      '@media (min-width: 992px)': {
        maxWidth: 800,
      },
    },
    small && {
      '@media (min-width: 576px)': {
        maxWidth: 300,
      },
    },
  );
