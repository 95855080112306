export const productBox = (theme) => ({
  border: `1px solid ${theme.primary}`,
  cursor: 'pointer',
  '& .product-image-wrap': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2em',
    '& .product-image': {
      maxWidth: '100%',
      height: 'auto',
    },
  },
  '& .product-name': {
    borderTop: `1px solid ${theme.primary}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1em',
    color: theme.secondaryDark,

    '& > p': {
      fontSize: '0.9rem',
      textTransform: 'uppercase',
      margin: 0,
      letterSpacing: 1.5,
    },
    '& > i': {
      color: theme.primary,
    },
  },

  '&:hover': {
    border: `1px solid ${theme.secondaryDarker}`,
    '& .product-name': {
      '& > i': {
        color: theme.secondaryDarker,
      },
    },
  },

  '@media (max-width: 1199px)': {
    '& .product-name': {
      '& > p': {
        fontSize: '0.7rem',
      },
      '& > i': {
        fontSize: '0.9rem',
      },
    },
  },

  '@media (max-width: 991px)': {
    '& .product-name': {
      '& > p': {
        fontSize: '0.6rem',
      },
      '& > i': {
        fontSize: '0.8rem',
      },
    },
  },
});
