import { getReq } from './axios/makeRequest';
import { getPocketBaseParams } from './models/pocketbase';

const baseUrl = `${pocketbaseUrl}/api/collections/news/records`;

export const getNews = (options = {}) => {
  const params = getPocketBaseParams(options);
  return getReq(`${baseUrl}?${params}`);
};

export const getNewsDetails = (id, options = {}) => {
  const params = getPocketBaseParams(options);
  return getReq(`${baseUrl}/${id}?${params}`);
};
