import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { concatStrings } from '../../utils';
import { baseIcon } from './styles';

const Icon = (props) => {
  const { onClick, iconName, className, linkTo } = props;
  const theme = useTheme();

  const iconCore = (
    <i
      role="presentation"
      className={concatStrings(iconName, !linkTo && className)}
      css={baseIcon(props, theme)}
      onClick={onClick}
    />
  );

  return linkTo ? (
    <Link to={linkTo} className={className}>
      {iconCore}
    </Link>
  ) : (
    iconCore
  );
};

Icon.propTypes = {
  onClick: PropTypes.func,
  iconName: PropTypes.string,
  className: PropTypes.string,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Icon;
