/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isFunction } from 'lodash-es';
import { ReactComponent as GoToArrow } from 'assets/icons/go-to-icon.svg';
import { linkButton } from './styles';

const sizes = {
  small: {
    fontSize: '0.8rem',
    iconSize: '0.7rem',
  },
  medium: {
    fontSize: '1rem',
    iconSize: '0.8rem',
  },
};

const ArrowLink = ({ title, size = 'medium', onClick, linkTo, className }) => {
  return linkTo ? (
    <Link to={linkTo} className={className} css={linkButton(sizes[size])}>
      <p>{title}</p>
      <GoToArrow />
    </Link>
  ) : (
    <div
      role="button"
      tabIndex={0}
      css={linkButton(sizes[size])}
      className={className}
      {...(isFunction(onClick) && { onClick })}>
      <p>{title}</p>
      <GoToArrow />
    </div>
  );
};

ArrowLink.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  onClick: PropTypes.func,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
};

export default ArrowLink;
