/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { useLocation } from 'react-router-dom';
import MobileLanguages from '../MobileLanguages';
import MobileNavDropdown from '../MobileNavDropdown';
import { MobileProductsNavDropdown } from '../../screens/Products';
import { navBarBurgerIcon, tooltipContainer, navLinkStyle, navLinksWrap } from './styles';

const MobileNav = () => {
  const location = useLocation();
  const { getText } = useTranslations();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    open ? document.body.style.setProperty('overflow', 'hidden') : document.body.style.removeProperty('overflow');
  }, [open]);

  useEffect(() => {
    open && setOpen(false);
  }, [location]);

  const toggleDropdown = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <div tabIndex={-1} role="button" css={navBarBurgerIcon(open)} open={open} onClick={toggleDropdown}>
        <div className="line" />
        <div className="line" />
        <div className="line" />
      </div>
      {open && (
        <div css={tooltipContainer}>
          <div css={navLinksWrap}>
            <MobileNavDropdown name="products" content={<MobileProductsNavDropdown />} />
            <NavLink to="/network" role="button" tabIndex={0} css={navLinkStyle}>
              {getText('network')}
            </NavLink>
            <NavLink to="/news" role="button" tabIndex={0} css={navLinkStyle}>
              {getText('news')}
            </NavLink>
            <NavLink to="/events" role="button" tabIndex={0} css={navLinkStyle}>
              {getText('events')}
            </NavLink>
          </div>
          <MobileLanguages />
        </div>
      )}
    </>
  );
};

export default MobileNav;
