import { Children } from 'react';
import { isArray, isString, isNumber, isEqual, isNil, isPlainObject, pick } from 'lodash-es';
import hash from 'object-hash';
import { removeNodes } from '../Dropdown/utils';
import { noValue } from '../../utils';

export const getFormChilds = (children) => {
  const newChildrens = [];
  const getFlatList = (childs) => {
    const childChildren = Children.toArray(childs).filter(Boolean);
    childChildren.forEach((child) => {
      if (child.type.displayName === 'FormParagraph') getFlatList(child.props.children);
      else newChildrens.push(child);
    });
  };
  getFlatList(children);
  return newChildrens;
};

export const mapPreFilledValues = (preFilledValues, children) => {
  const newMappedValues = {};
  Object.keys(preFilledValues || {}).forEach((id) => {
    const correspondingChild = getFormChilds(children).find((el) => el.props.id === id);
    if (correspondingChild) {
      newMappedValues[id] = correspondingChild.props.mapValue
        ? correspondingChild.props.mapValue(preFilledValues[id])
        : preFilledValues[id];
    } else newMappedValues[id] = preFilledValues[id];
  });
  return newMappedValues;
};

// Check if the values are defined because lodash isEqual with 2 undefined returns true
const compareValues = (source, value, keys) => {
  const isStrNum = (val) => isString(val) || isNumber(val);

  // Compare string with object if they are related to the same thing
  // Example: code as a string and object with code property if the codes are same they are equal
  if (isStrNum(source) && isPlainObject(value)) return keys.some((key) => source === value[key]);
  if (isPlainObject(source) && isStrNum(value)) return keys.some((key) => value === source[key]);

  // Compare two objects, if they have match
  const areDef = (key) => !isNil(source[key]) && !isNil(value[key]);
  const areSame = (key) => isEqual(source[key], value[key]);
  const compare = (key) => areDef(key) && areSame(key);
  if (isPlainObject(source) && isPlainObject(value)) return keys.some(compare);

  const hasSort = (item) => (isArray(item) ? [...item].sort() : item);

  return isEqual(hasSort(source), hasSort(value));
};

export const areEqual = (val, id, initValue, children) => {
  if (isNil(initValue) && noValue(val)) return true;

  const correspondChild = getFormChilds(children).find((e) => e.props.id === id);

  if (!correspondChild) return null;

  const { displayKey = 'label', uniqueKey = 'value' } = correspondChild.props;
  const keys = [displayKey, uniqueKey];

  return compareValues(initValue, val, keys);
};

const baseDependenciesList = ['disabled', 'className', 'readOnly', 'value', 'memoDependencies', 'isTouched'];

export const compareInputProps = (props) => [
  hash({
    props: pick(props, baseDependenciesList),
  }),
];

export const compareDropdownProps = (props) => [
  hash({
    props: {
      ...pick(props, baseDependenciesList),
      options: removeNodes(props.options, props.displayKey, props.uniqueKey),
    },
  }),
];
