import academyLogo from 'assets/products/one-academy.svg';
import academyLogoDark from 'assets/products/one-academy-dark.svg';
import academyLogoDarkNoText from 'assets/products/one-academy-dark-no-text.svg';
import academyBgImage from 'assets/products/academy-bg-image.jpg';
// import forexLogo from 'assets/products/one-forex-dark.svg';
// import forexLogoDark from 'assets/products/one-forex.svg';
import charityLogo from 'assets/products/one-charity.svg';
import charityLogoDark from 'assets/products/one-charity-dark.svg';
import charityLogoDarkNoText from 'assets/products/one-charity-dark-no-text.svg';
import charityBgImage from 'assets/products/charity-bg-image.jpg';
import vitaLogo from 'assets/products/one-vita.svg';
import vitaLogoDark from 'assets/products/one-vita-dark.svg';
import vitaLogoDarkNoText from 'assets/products/one-charity-dark-no-text.svg';
import vitaBgImage from 'assets/products/vita-bg-image.jpg';
import voyageLogo from 'assets/products/one-voyage.svg';
import voyageLogoDark from 'assets/products/one-voyage-dark.svg';
import voyageLogoDarkNoText from 'assets/products/one-voyage-dark-no-text.svg';
import voyageBgImage from 'assets/products/voyage-bg-image.jpg';
import dealshakerLogo from 'assets/products/dealshaker.svg';
import dealshakerLogoDark from 'assets/products/dealshaker-dark.svg';
import dealshakerLogoDarkNoText from 'assets/products/dealshaker-dark-no-text.svg';
import dealshakerBgImage from 'assets/products/dealshaker-bg-image.jpg';
import videoAcademy from 'assets/videos/one-academy-video.mp4';
import videoCharity from 'assets/videos/one-charity-video.mp4';
import videoVita from 'assets/videos/one-vita-video.mp4';
import videoDealshaker from 'assets/videos/dealshaker-video.mp4';
import videoVoyage from 'assets/videos/one-voyage-video.mp4';

export const products = [
  {
    id: 1,
    name: 'One Academy',
    image: academyLogo,
    imageDark: academyLogoDark,
    imageDarkNoText: academyLogoDarkNoText,
    video: videoAcademy,
    bgImage: academyBgImage,
    shortTextKey: 'academyShortText',
    sloganTextKey: 'academySloganText',
    descriptionTitleKey: 'academyDescriptionTitle',
    descriptionTextKey: 'academyDescriptionText',
    website: 'https://academy.oneecosystem.eu',
  },
  //   {
  //     id: 2,
  //     name: 'One Forex',
  //     image: forexLogo,
  //     imageDark: forexLogoDark,
  //     shortTextKey: 'forexShortText',
  //   },
  {
    id: 3,
    name: 'One Charity',
    image: charityLogo,
    imageDark: charityLogoDark,
    imageDarkNoText: charityLogoDarkNoText,
    video: videoCharity,
    bgImage: charityBgImage,
    shortTextKey: 'charityShortText',
    sloganTextKey: 'charitySloganText',
    descriptionTitleKey: 'charityDescriptionTitle',
    descriptionTextKey: 'charityDescriptionText',
  },
  {
    id: 4,
    name: 'One Vita',
    image: vitaLogo,
    imageDark: vitaLogoDark,
    imageDarkNoText: vitaLogoDarkNoText,
    video: videoVita,
    bgImage: vitaBgImage,
    shortTextKey: 'vitaShortText',
    sloganTextKey: 'vitaSloganText',
    descriptionTitleKey: 'vitaDescriptionTitle',
    descriptionTextKey: 'vitaDescriptionText',
    website: 'https://onevita.eu',
  },
  {
    id: 5,
    name: 'Dealshaker',
    image: dealshakerLogo,
    imageDark: dealshakerLogoDark,
    imageDarkNoText: dealshakerLogoDarkNoText,
    video: videoDealshaker,
    bgImage: dealshakerBgImage,
    shortTextKey: 'dealshakerShortText',
    sloganTextKey: 'dealshakerSloganText',
    descriptionTitleKey: 'dealshakerDescriptionTitle',
    descriptionTextKey: 'dealshakerDescriptionText',
    website: 'https://dealshaker.com/',
  },
  {
    id: 6,
    name: 'One Voyage',
    upcoming: true,
    image: voyageLogo,
    imageDark: voyageLogoDark,
    imageDarkNoText: voyageLogoDarkNoText,
    video: videoVoyage,
    bgImage: voyageBgImage,
    shortTextKey: 'voyageShortText',
    sloganTextKey: 'voyageSloganText',
    descriptionTitleKey: 'voyageDescriptionTitle',
    descriptionTextKey: 'voyageDescriptionText',
  },
];
