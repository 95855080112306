import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { useTheme } from '@emotion/react';
import { Icon } from '../Icon';
import { Timer } from '../../utils';
import { ReactComponent as infoIcon } from '../../assets/icons/icn-alert-notice-2.svg';
import { ReactComponent as successIcon } from '../../assets/icons/icn-alert-success.svg';
import { ReactComponent as warningIcon } from '../../assets/icons/icn-alert-warning.svg';
import { ReactComponent as errorIcon } from '../../assets/icons/icn-alert-error.svg';
import { notification, notifContainer } from './styles';

const getIcon = {
  success: successIcon,
  error: errorIcon,
  warning: warningIcon,
  info: infoIcon,
};

const Notification = (props) => {
  const {
    id,
    onRemoval,
    title,
    message,
    content,
    duration = 117000,
    toggleRemoval,
    onClick,
    type,
    iconName,
    alwaysShow = false,
    pauseOnHover = true,
  } = props;
  const timerRef = useRef();
  const callbackRef = useRef(onRemoval);
  const [hide, setHide] = useState(false);
  const theme = useTheme();

  const onTransitionStart = () => {
    if (!duration || alwaysShow) return;
    const notifTimer = new Timer(() => setHide(true), duration);
    timerRef.current = notifTimer;
    notifTimer.start();
  };

  useEffect(() => {
    pauseOnHover && onTransitionStart();

    return () => {
      pauseOnHover && timerRef.current?.clear();
    };
  }, []);

  const onTransitionEnd = () => {
    toggleRemoval(id);
    isFunction(callbackRef) && callbackRef(props);
  };

  const onMouseEnter = () => timerRef.current?.pause();

  const onMouseLeave = () => timerRef.current?.start();

  const handleClick = () => {
    callbackRef.current = isFunction(onClick) ? onClick : onRemoval;
    setHide(true);
  };

  const NotificationIcon = getIcon[type];

  return (
    <div
      role="presentation"
      css={notification(type, hide, theme)}
      onMouseEnter={pauseOnHover ? onMouseEnter : null}
      onMouseLeave={pauseOnHover ? onMouseLeave : null}
      onClick={handleClick}
      onTransitionEnd={hide ? onTransitionEnd : onTransitionStart}>
      {content ?? (
        <div css={notifContainer(type, theme)}>
          {iconName ? <Icon material iconName={iconName} /> : <NotificationIcon />}
          <span>
            {title && <h4>{title}</h4>}
            {message && <span>{message}</span>}
          </span>
        </div>
      )}
    </div>
  );
};

Notification.propTypes = {
  id: PropTypes.string,
  onRemoval: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  content: PropTypes.node,
  duration: PropTypes.number,
  type: PropTypes.string,
  toggleRemoval: PropTypes.func,
  onClick: PropTypes.func,
  alwaysShow: PropTypes.bool,
  iconName: PropTypes.string,
  pauseOnHover: PropTypes.bool,
};

export default Notification;
