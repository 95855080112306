import { css } from '@emotion/react';

export const containerStyle = ({ zIndex, className, fullScreen, fixed }) =>
  css(
    {
      position: fixed ? 'fixed' : 'absolute',
      zIndex: zIndex || 200,
    },
    fullScreen && {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    className,
  );
