import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { setStrankConfig } from '@veraio/strank';
import BaseApp from './screens/App';
import ScrollToTop from './components/ScrollToTop';
import { Theme, LoadIcons, Notifications } from './components';
import initGlobalStyles from './styles/globalCSS';

setStrankConfig({ parseHtml: true, environment: strankEnvironment, storage: window.localStorage });

const App = () => (
  <Theme initGlobalStyles={initGlobalStyles}>
    <Notifications />
    <Router>
      <ScrollToTop>
        <BaseApp />
      </ScrollToTop>
      <LoadIcons />
    </Router>
  </Theme>
);

const container = document.getElementById('oneecosystem-root');
const root = createRoot(container);
root.render(<App />);
