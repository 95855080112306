/* eslint-disable no-unused-vars */
import React from 'react';
import { Row, Col, NavProductBox } from 'components';
import { useTranslations } from '@veraio/strank';
import { products } from 'enums';
import { wrap } from './styles';

const ProductsNavDropdown = () => {
  const { getText } = useTranslations();
  return (
    <div css={wrap}>
      <h4>{getText('oneEcosystemProducts')}</h4>
      <Row container horizontalGap={8} verticalGap={8}>
        {products?.map((el) => (
          <Col sm key={el?.id}>
            <NavProductBox data={el} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ProductsNavDropdown;
