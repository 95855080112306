import moment from 'moment';

export const defaultDate = '0001-01-01T00:00:00';

export const validDate = (date) => (date === defaultDate ? null : date);

export const endOfTheDay = (inputDate) => inputDate.hours(23).minutes(59).seconds(59);

export const dateFormat = (date) => (moment(date).isValid() ? moment(date).format('MMMM DD, YYYY') : '');

export const eventDateFormat = (startDate, endDate) => {
  if (!startDate || !endDate) return { month: '', day: '' };

  const start = moment(startDate);
  const end = moment(endDate);
  const isSameDate = start.isSame(end);
  const isSameMonth = start.isSame(end, 'month');

  if (isSameDate) return { month: start.format('MMM'), day: start.format('DD') };

  if (!isSameDate && isSameMonth)
    return { month: start.format('MMM'), day: `${start.format('DD')} - ${end.format('DD')}` };

  if (!isSameDate && !isSameMonth) {
    return {
      month: `${start.format('MMM')} - ${end.format('MMM')}`,
      day: `${start.format('DD')} - ${end.format('DD')}`,
    };
  }
};

export const isEventPassed = (date) => {
  const today = moment();
  return moment(date).isBefore(today, 'day');
};
