import { useState, useEffect, useRef } from 'react';
import { useTranslations } from '@veraio/strank';
import { isNil } from 'lodash-es';
import { Link } from 'react-router-dom';
import { Row, Col, Breadcrumbs, Icon, showError } from 'components';
// import { continents, countries } from 'enums';
import { getCountries, getContinents } from 'services';
import { getImagePath } from 'utils';
import { header, body, countryListItem, countryBox } from './styles';

const CountryBoards = () => {
  const { getText } = useTranslations();
  const [countries, setCountries] = useState(null);
  const [continents, setContinents] = useState(null);
  const continentIdRef = useRef();

  const items = [
    {
      label: getText('networkStructure'),
      url: '/network',
    },
    {
      label: getText('countryBoards'),
      uppercase: true,
    },
  ];

  useEffect(() => {
    fetchContinents();
  }, []);

  const fetchCountries = async (page, options) => {
    const [res, err] = await getCountries({
      page,
      skipTotal: true,
      perPage: 50,
      sort: 'name',
      filter: options?.continentId && `(continentId='${options?.continentId}')`,
    });
    if (err) return showError(err?.data);

    setCountries((prev) =>
      isNil(prev) || page === 1
        ? res
        : {
            ...res,
            items: [...prev.items, ...res.items],
          },
    );
  };

  const fetchContinents = async () => {
    const [res, err] = await getContinents({
      perPage: 10,
      skipTotal: true,
    });
    if (err) return showError(err?.data);
    setContinents(res);

    const firstSelected = res?.items?.at(0)?.id;

    if (firstSelected) {
      continentIdRef.current = firstSelected;
      fetchCountries(1, { continentId: firstSelected });
    }
  };

  const handleChangeContinent = (id) => {
    if (id === continentIdRef.current) return;
    continentIdRef.current = id;
    fetchCountries(1, { continentId: id });
  };

  return (
    <>
      <section css={header}>
        <Breadcrumbs items={items} className="header-breadcrumbs" />
        <Link to="/network">
          <div className="go-back-to-all">
            <Icon iconName="la la-arrow-left" />
            <p>{getText('networkStructure')}</p>
          </div>
        </Link>
        <h2>{getText('countryBoards')}</h2>
        <Row container horizontalGap={16}>
          <Col md={6} lg={6} xl={6}>
            <p data-strank-key="countryBoardsPageTextLeft">{getText('countryBoardsPageTextLeft')}</p>
          </Col>
          <Col md={6} lg={6} xl={6}>
            <p data-strank-key="countryBoardsPageTextRight">{getText('countryBoardsPageTextRight')}</p>
          </Col>
        </Row>
      </section>
      <div css={body}>
        <Row container>
          <Col>
            <div className="country-list-header">
              <h5 className="country-list-title">{getText('countryBoardsMembers')}</h5>
              <div className="country-regions-list">
                {continents?.items?.map((el) => (
                  <div
                    key={el?.id}
                    role="button"
                    tabIndex={0}
                    onClick={() => handleChangeContinent(el?.id)}
                    css={countryListItem(continentIdRef.current === el?.id)}>
                    {el?.name}
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>

        <Row container>
          {countries?.items?.map((el) => (
            <Col key={el?.id} md={6} lg={4} xl={3}>
              <Link to={`/network/country-boards/${el?.id}`} css={countryBox(getImagePath(el))}>
                <div className="image-wrap">
                  <div className="image" />
                </div>
                <div className="text-wrap">
                  <h5>{el?.name}</h5> <Icon iconName="la la-arrow-right" />
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default CountryBoards;
