export const header = (theme) => ({
  padding: '2em 4em',
  '& .go-back-to-all': {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      margin: 0,
      textTransform: 'uppercase',
      color: theme.secondaryDark,
      fontSize: '1.2rem',
    },
    '& > i': {
      marginRight: '0.2em',
      color: theme.secondaryDark,
    },
    '&:hover': {
      '& > p': {
        color: theme.secondary,
      },
      '& > i': {
        color: theme.secondary,
      },
    },
  },
  '@media (max-width: 991px)': {
    padding: '2em',
    '&  > div ': {
      maxWidth: '100%',
    },
  },
});

export const body = (theme) => ({
  padding: '0  4em 0 4em',
  marginBottom: '4em',

  '& .member-info': {
    paddingLeft: '4em',
    '& .member-name': {
      textTransform: 'uppercase',
    },
    '& .separator': {
      width: '100%',
      height: 1,
      backgroundColor: theme.primary,
      marginTop: '2em',
      marginBottom: '2em',
    },
    '& .icon-info ': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '2em',
      '& > svg ': {
        width: '1.5rem',
        marginRight: '0.5em',
      },
      '& > p ': {
        margin: 0,
        textTransform: 'uppercase',
        fontSize: '0.9rem',
      },
    },
    '& .member-details': {
      fontSize: '1.2rem',
      marginBottom: '2em',
    },
    '& .member-contact-button': {
      border: `1px solid ${theme.primary}`,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '1.5em',
      '& > svg ': {
        width: '2rem',
        marginRight: '1em',
      },
      '& > p ': {
        flex: '1 0 0%',
        color: theme.secondaryDark,
        margin: 0,
        fontSize: '1.2rem',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
  },

  '@media (max-width: 991px)': {
    padding: '0 2em 0 2em',
    marginBottom: '3em',
    '&  > div ': {
      maxWidth: '100%',
    },
  },
  '@media (max-width: 767px)': {
    padding: '0',
    '& .member-info': {
      padding: '4em 2em',
    },
  },
});

export const memberImage = (image) => (theme) => ({
  backgroundColor: theme.primaryDark,
  backgroundImage: `url(${image})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  aspectRatio: ' 1 / 1',
});
