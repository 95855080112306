import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import Button from './Button';
import Ripple from '../Ripple';
import { buttonDropdownContainer, buttonDropdownOptionsContainer, buttonDropdownOptionItem } from './styles';

const ButtonDropdown = ({
  children,
  options = [],
  disabled = !options.length,
  optionListClassName,
  ...buttonProps
}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const handleBlur = ({ currentTarget }) =>
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement) && isOpen) setIsOpen(false);
    });

  const renderBaseOptionElement = (option, i) => {
    const optionRender = option.render ? option.render : option.label;
    return (
      <div key={`buttonDropdownItem${i * Math.random()}`} role="presentation" onClick={option.onClick}>
        <Ripple backColor="secondaryDark" css={buttonDropdownOptionItem(theme)}>
          {optionRender}
        </Ripple>
      </div>
    );
  };

  return (
    <div css={buttonDropdownContainer} onBlur={handleBlur}>
      <Button {...buttonProps} disabled={disabled} onClick={() => setIsOpen(!isOpen)}>
        {children}
      </Button>
      <div css={buttonDropdownOptionsContainer(isOpen, options.length, optionListClassName, theme)}>
        {options.map(renderBaseOptionElement)}
      </div>
    </div>
  );
};

ButtonDropdown.propTypes = {
  options: PropTypes.array,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  optionListClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ButtonDropdown;
