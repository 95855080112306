export const navBarHeaderContainer = (theme) => ({
  backgroundColor: theme.primaryDarker,
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.primary}`,
  alignItems: 'center',
  height: '4rem',
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 9,
});

export const navLogoWrap = {
  maxWidth: '10rem',
};
