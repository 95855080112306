import { Children, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigationControls } from '../NavigationContext';
import MenuList from './MenuList';
import Content from './Content';
import { mainContainer } from './styles';

const SideNav = ({ children: initChildren = [], routes, notFoundComponent, homeRoute }) => {
  const children = Children.toArray(initChildren).filter(Boolean);
  const { changeAllRoutes } = useNavigationControls();

  useEffect(() => {
    extractAllRoutes();
  }, []);

  const extractAllRoutes = () => {
    const allRoutesList = [...routes];

    const extractNestedRoutes = (elements) =>
      elements.forEach((el) => {
        const menu = el.props ?? el;
        menu.subMenus ? extractNestedRoutes(menu.subMenus) : allRoutesList.push(menu);
      });

    extractNestedRoutes(children);

    changeAllRoutes(allRoutesList);
  };

  return (
    <main css={mainContainer}>
      <MenuList sideNavChildrens={children} />
      <Content notFoundComponent={notFoundComponent} homeRoute={homeRoute} />
    </main>
  );
};

SideNav.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  routes: PropTypes.array,
  notFoundComponent: PropTypes.func,
  homeRoute: PropTypes.string,
};

export default SideNav;
