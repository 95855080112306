import { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useTranslations } from '@veraio/strank';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import BodyOverflow from '../../BodyOverflow';
import { Icon } from '../../Icon';
import { ReactComponent as IconOpen } from 'assets/icons/chevron-down-open.svg';
import { ReactComponent as IconClose } from 'assets/icons/chevron-down-close.svg';
import { modalWrap, modalInner, navLinkStyle, navIcon, closeIcon } from './styles';

const NavDropdown = forwardRef((props, ref) => {
  const { name, content } = props;
  const location = useLocation();
  const { getText } = useTranslations();
  const [show, setShow] = useState(false);
  const NavIcon = show ? IconOpen : IconClose;
  const isActive = location?.pathname?.includes(name);

  useImperativeHandle(ref, () => ({
    open: () => setShow(true),
    close: () => setShow(false),
  }));

  useEffect(() => {
    show ? document.body.style.setProperty('overflow', 'hidden') : document.body.style.removeProperty('overflow');
  }, [show]);

  useEffect(() => {
    show && setShow(false);
  }, [location]);

  const handleClick = (e) => {
    if (e.target.id === 'backdrop') setShow(false);
  };

  return (
    <>
      <div onClick={() => setShow(true)} tabIndex={0} role="button" css={navLinkStyle(isActive)}>
        {getText(name)}
        <NavIcon css={navIcon} />
      </div>
      {show && (
        <BodyOverflow fixed fullScreen zIndex={10000}>
          <section id="backdrop" role="presentation" onClick={handleClick} css={modalWrap}>
            <div css={modalInner}>
              <Icon onClick={() => setShow(false)} css={closeIcon} iconName="fa fa-times" />
              {content}
            </div>
          </section>
        </BodyOverflow>
      )}
    </>
  );
});

NavDropdown.propTypes = {
  name: PropTypes.string,
  content: PropTypes.node,
};

export default NavDropdown;
