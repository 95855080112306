import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import moment from 'moment';
import { isObject, omit } from 'lodash-es';
import { inputField } from './styles';

const Input = forwardRef(({ value, onChange, dateTimeProps, className, ...inputAttr }, ref) => {
  const Component = inputAttr.type === 'textarea' ? 'textarea' : 'input';
  const dateTimeFormat = dateTimeProps?.format ?? 'DD/MM/YYYY';

  return dateTimeProps ? (
    <Datetime
      dateFormat={dateTimeFormat}
      onBlur={inputAttr.onBlur}
      value={value && moment(value).format(dateTimeFormat)}
      closeOnSelect
      className={className}
      onChange={(val) =>
        isObject(val) &&
        onChange({
          target: {
            value: moment(val).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
          },
        })
      }
      inputProps={{
        ref,
        css: inputField(!!value),
        ...inputAttr,
      }}
      {...omit(dateTimeProps, 'format')}
    />
  ) : (
    <Component
      ref={ref}
      css={inputField(!!value)}
      className={className}
      value={value}
      onChange={onChange}
      {...inputAttr}
    />
  );
});

Input.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  dateTimeProps: PropTypes.any,
};

export default Input;
