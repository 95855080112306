/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
import { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { ThemeProvider, Global } from '@emotion/react';
import themeConfig from 'styles/theme.json';
import { globalCss } from '../styles';

const Theme = ({ children, initGlobalStyles, iconSets = {} }) => {
  useLayoutEffect(() => {
    setUpTheme();
  }, []);

  const setUpTheme = async () => {
    const { fontAwesome, lineAwesome, iconsMind } = {
      ...themeConfig,
      ...iconSets,
    };

    switch (true) {
      case fontAwesome:
        import('../Icon/icon-sets/font-awesome/font-awesome.css');
      case lineAwesome:
        import('../Icon/icon-sets/line-awesome/line-awesome.css');
      case iconsMind:
        import('../Icon/icon-sets/iconsmind/iconsmind.css');
    }
  };

  // TODO: Put some loader to be shown until fetching theme config
  return (
    <ThemeProvider theme={themeConfig}>
      <Global styles={globalCss(themeConfig)} />
      {isFunction(initGlobalStyles) && <Global styles={initGlobalStyles(themeConfig)} />}
      {children}
    </ThemeProvider>
  );
};

Theme.propTypes = {
  children: PropTypes.node,
  initGlobalStyles: PropTypes.func,
  iconSets: PropTypes.object,
};

export default Theme;
