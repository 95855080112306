/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from 'components';
import { productBox } from './styles';

const NavProductBox = ({ data }) => (
  <div css={productBox}>
    <Link to={{ pathname: `/products/${data?.id}`, state: { product: data } }}>
      <div className="product-image-wrap">
        <img src={data?.image} alt={`${data?.name}-logo`} className="product-image" />
      </div>
      <div className="product-name">
        <p>{data?.name}</p>
        <Icon iconName="fa fa-arrow-right" />
      </div>
    </Link>
  </div>
);

NavProductBox.propTypes = {
  data: PropTypes.object,
};

export default NavProductBox;
